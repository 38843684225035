import React, { useState, useEffect } from "react";
import FormWizard from "react-form-wizard-component";
import "react-form-wizard-component/dist/style.css";
// import { Wizard } from "react-wizardry";
import { connect } from 'react-redux';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import CheckIcon from '@mui/icons-material/Check';
import ImageUploaderSetting from '../components/ImageUploaderSetting';
import "react-wizardry/dist/react-wizardry.css";
import { Container, Typography, Button, Grid, TextField, Select, MenuItem, FormControl, Snackbar } from '@mui/material';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import SideBarMenu from '../components/SideBarMenu';
import { makeStyles } from "tss-react/mui";
import { ProSidebarProvider } from 'react-pro-sidebar';
import { initializeApp } from 'firebase/app';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { collection, query, where, getDocs, getDoc, getFirestore, doc, updateDoc } from 'firebase/firestore';
import IconButton from "@material-ui/core/IconButton";
import { CircularProgress } from '@mui/material';
import { ChromePicker } from 'react-color';

const DashboardContainer = ({ updateCompanyData, children }) => {
  const [companyId, setCompanyId] = useState(null);
  const [companyData, setCompanyData] = useState(null);
  const [senderEmail, setSenderEmail] = useState(null);
  const [footerLogo, setFooterLogo] = useState(null);
  const [logo, setLogo] = useState(null);
  const [primaryColor, setPrimaryColor] = useState('');
  const [secondaryColor, setSecondaryColor] = useState('');
  const [displayPrimaryColorPicker, setDisplayPrimaryColorPicker] = useState(false);
  const [displaySecondaryColorPicker, setDisplaySecondaryColorPicker] = useState(false);
  const [loading, setLoading] = useState(true);
  const [isSenderEmailButtonDisabled, setIsSenderEmailButtonDisabled] = useState(false);
  const [snackBar, setSnackBar] = useState({
    isOpen: false,
    message: '',
  });
  const firebaseConfigSecondary = {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSEGING_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_APP_ID,
    measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
  };
  const popover = {
    position: 'absolute',
    zIndex: '2',
  }
  const cover = {
    position: 'fixed',
    top: '0px',
    right: '0px',
    bottom: '0px',
    left: '0px',
  }
  const app = initializeApp(firebaseConfigSecondary);
  const db = getFirestore(app);
  // Initialize Firebase Auth
  const auth = getAuth(app);
  let companyName = '';
  let companySenderEmail = '';

  const useStyles = makeStyles()(
    () => ({
      main: {
        width: '100%',
        '@media only screen and (min-width: 1200px)': {
          width: '100%',
        },
      },
      navContainer: {
        maxWidth: '20%'
      },
      container: {
        boxSizing: 'border-box',
        display: 'flex',
        flexWrap: 'nowrap',
        width: '100%',
        flexDirection: 'row',
        marginTop: 0,
        justifyContent: 'space-between',
        alignItems: 'flex-start',
      },
      welomeText: {
        color: '#ff6633',
        fontWeight: 700,
      },
      paper: {
        display: 'flex',
        flexWrap: 'nowrap',
        alignItems: 'center',
        flexDirection: 'column',
        marginTop: 210,
      },
      confirmBtnConfirm: {
        background: 'blue',
      },
      wizardTitle: {
        fontWeight: 'bold',
        fontSize: '40px'
      },
      iconButton: {
        border: '1px solid #54d2d2 !important',
        color: '#54d2d2 !important',
        borderRadius: '50px !important',

      },
      confirmCompanyEmailSender: {
        border: '1px solid #ff6150 !important',
        background: '#ff6150 !important',
        color: 'white !important',
        borderRadius: '5px !important',
      },
      packageItem: {
        background: 'white',
        padding: '0px',
        borderRadius: '20px',
        height: '250px',
        transition: 'border 0.3s ease',
        border: '1px solid transparent',
        cursor: 'pointer',
        '&:hover': {
          border: '1px solid #FF6150'
        }
      },
      packageItemList: {
        textAlign: 'right',
        padding: '30px 25px 0px 0px !important'
      },
      packageItemSelected: {
        border: '1px solid #FF6150', // Border when selected
      },
      packageItemTitle: {
        fontSize: '14px',
        background: '#ffcb27',
        height: '60px',
        borderRadius: '20px',
        padding: 0,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',

        '&:hover': {
          borderBottom: '1px solid #FF6150'
        }
      },
      packageContainer: {
        gap: '15px',
        display: 'flex',
        justifyContent: 'center'
      },
      packageItemSelectTag: {
        display: 'flex',
        flexDirection: 'column !important',
      },
    })
  );
  const [selectedPackage, setSelectedPackage] = useState(null);
  const [selectedPackageSelect, setSelectedPackageSelect] = useState('');

  const handlePackageClick = (index) => {
    setSelectedPackage(index);
  };
  const packages = [
    {
      title: 'الحزمة المجّانية',
      details: [
        '500 بريد إلكتروني شهرياً',
        'دعم التخصيص والتضمين في موقعك',
        'دعم فني وتقني كل أيام الاسبوع',
      ],
    },
    {
      title: 'الحزمة المرنة',
      details: [
        '0.70$ لكل 1000 بريد إلكتروني + 25$ في الشهر',
        'حتى 50 ألف بريد إلكتروني شهري',
        'تخصيص وإضافات إعلان',
        'مساعد موجز الرقمي',
        'دعم التخصيص والتضمين في موقعك',
        'دعم فني وتقتني كل أيام الأسبوع',
      ],
    },
    {
      title: 'الحزمة الثابتة',
      details: [
        'حتى 1 مليون بريد إلكتروني شهري',
        'تخصيص وإضافات إعلان',
        'مساعد موجز الرقمي',
        'دعم التخصيص والتضمين في موقعك',
        'دعم فني وتقتني كل أيام الأسبوع',
      ],
      type: 'select', // Add a type to indicate special content
    },
  ];
  const handleChangePackageSelect = (event) => {
    setSelectedPackageSelect(event.target.value);
  };
  const handlePrimaryColorClick = () => {
    setDisplayPrimaryColorPicker(displayColorPicker => !displayColorPicker);
  };

  const handlePrimaryColorClose = () => {
    setDisplayPrimaryColorPicker(false);
  };
  const handleSecondaryColorClick = () => {
    setDisplaySecondaryColorPicker(displayColorPicker => !displayColorPicker);
  };

  const handleSecondaryColorClose = () => {
    setDisplaySecondaryColorPicker(false);
  };
  const updateDefaultReplyTo = (value) => {
    setSenderEmail(value);
  };
  const fetchUserData = async () => {

    const currentUser = auth.currentUser;

    if (currentUser) {
      const q = query(collection(db, 'Users'), where('uid', '==', currentUser.uid));
      const querySnapshot = await getDocs(q);

      querySnapshot.forEach(async (doc) => {

        let company = doc.data().company;
        const comp = query(collection(db, 'Companies'), where('personalProfile.companyData.company', '==', company));
        const compSnapshot = await getDocs(comp);
        compSnapshot.forEach((_doc) => {
          setCompanyData(_doc.data());
          setCompanyId(_doc.id);
          setLoading(false);
        });
      });
    }
  };
  useEffect(() => {
    fetchUserData();
    // console.log(props);
  }, []);
  const [firstTabInput, setFirstTabInput] = useState("");
  const handleComplete = () => {
    const updatedCompanyData = {
      settings: {
        footer: {
          footerLogo: footerLogo,
        },
        view: {
          logo: logo,
          primaryColor: primaryColor,
          secondaryColor: secondaryColor,
          // Add other fields as needed
        },
        senderSettings: {
          defaultReplyTo: senderEmail,
          email: senderEmail,
          // Add other sender settings as needed
        },
      }
    };
    // Update document in Firestore
    db.collection('Companies').doc(companyId)
      .update(updatedCompanyData)
      .then(() => {
        console.log('Company data updated successfully!');
        // Handle success, e.g., show a success message
      })
      .catch((error) => {
        console.error('Error updating company data:', error);
        // Handle error, e.g., show an error message
      });
    console.log("Form completed!");
    console.log("footer: " + footerLogo);
    console.log("logo: " + logo);
    console.log("color 1: " + primaryColor);
    console.log("color 2: " + secondaryColor);
    console.log("email: " + senderEmail);
    console.log("package:" + selectedPackage);
    console.log("package-select:" + selectedPackageSelect);
    console.log(companyData);
    // Handle form completion logic here
  };
  // check validate tab
  const checkValidateTab = () => {
    console.log(firstTabInput);
    if (firstTabInput === "") {
      return false;
    }
    return true;
  };
  // error messages
  const errorMessages = () => {
    // you can add alert or console.log or any thing you want
    alert("Please fill in the required fields");
  };
  const createIdentityAWS = async () => {
    // Regular expression for basic email validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    // Check if senderEmail is empty
    if (!senderEmail) {
      setSnackBar({
        isOpen: true,
        message: 'Email address is required',
      });
      return;
    }

    // Check if senderEmail is not a valid email
    if (!emailRegex.test(senderEmail)) {
      setSnackBar({
        isOpen: true,
        message: 'Invalid email address format',
      });
      return;
    }

    try {
      const response = await fetch('https://us-central1-mujaz-platform.cloudfunctions.net/createAWSIdentity', {
        method: 'POST',
        headers: {
          'Access-Control-Allow-Origin': 'https://mujaz.io',
          'Access-Control-Allow-Methods': 'post',
          'Content-Type': 'application/json',
          'Access-Control-Allow-Headers': 'Content-Type, Authorization',
        },
        body: JSON.stringify({ email: senderEmail }),
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const data = await response.text();
      setSnackBar({
        isOpen: true,
        message: 'تم إضافة البريد المرسل بنجاح',
      });
      updateCompanySenderSettings(companyId, senderEmail)
    } catch (error) {
      setSnackBar({
        isOpen: true,
        message: 'Failed to create email identity: ' + error.message,
      });
    }
  };
  // Function to update the defaultReplyTo
  const updateCompanySenderSettings = async (companyId, senderEmail) => {

    try {
      // Reference to the specific company document using companyId
      const companyDocRef = doc(db, 'Companies', companyId);

      const companyDocSnap = await getDoc(companyDocRef);

      if (companyDocSnap.exists()) {
        // Document data
        const companyData = companyDocSnap.data();
        companyName = companyData?.personalProfile?.companyData?.company;

        // Update the field 'settings.senderSettings.defaultReplyTo'
        await updateDoc(companyDocRef, {
          'settings.senderSettings.defaultReplyTo': senderEmail,
          'settings.senderSettings.email': senderEmail,
          'settings.senderSettings.name': companyName,
          'settings.senderSettings.defaultFrom': companyName+' <'+senderEmail+'>',
        });

        console.log('Company settings updated successfully!');
        updateCompanyData({senderEmail: senderEmail, companyName: companyName});
        setIsSenderEmailButtonDisabled(true);
      }
    } catch (error) {
      console.error('Error updating company settings: ', error);
    }
  };
  const { classes } = useStyles();
  const nextButtonTemplate = (onClick) => (
    <IconButton size="small" color="primary" variant="outlined" className={classes.iconButton} onClick={onClick}>
      <ArrowBackIcon />
    </IconButton>
  )
  const backButtonTemplate = (onClick) => (
    <IconButton size="small" color="primary" variant="outlined" className={classes.iconButton} onClick={onClick}>
      <ArrowForwardIcon />
    </IconButton>
  )
  const finishButtonTemplate = (onClick) => (
    <IconButton size="small" color="primary" variant="outlined" className={classes.iconButton} onClick={finishWizard}>
      <CheckIcon />
    </IconButton>
  )
  const finishWizard = async () => {
    if (!senderEmail ||
      !logo ||
      !footerLogo ||
      !primaryColor ||
      !secondaryColor) {

      // Show error message in the snackbar
      setSnackBar({
        isOpen: true,
        message: 'Fill all the required fields'
      });
      return;
    }
    try {
      const companyDocRef = doc(db, 'Companies', companyId);

      // Update the company data with the provided settings
      await updateDoc(companyDocRef, {
        'settings.view.logo': logo,
        'settings.footer.footerLogo': footerLogo,
        'settings.view.primaryColor': primaryColor,
        'settings.view.secondaryColor': secondaryColor
      });

      // Refresh the page after updating
      window.location.reload();

    } catch (error) {
      console.error('Error updating company data: ', error);
    }
  }
  if (loading) {
    return (
      <CircularProgress />
    );
  } else {
    if (!companyData?.settings?.senderSettings?.defaultReplyTo) {
      // if (!true) {
      return (
        <>
          <Grid container columns={{ xs: 12, sm: 12, md: 12 }} style={{ minHeight: '100vh' }}
            justifyContent="center"
            alignItems="center">
            <Grid item xs={6} md={6}>
              <FormWizard
                stepSize="sm"
                color="#54D2D2"
                title={<Typography variant="p" className={classes.wizardTitle}>مرحبا بك في موجز</Typography>}
                onComplete={handleComplete}
                nextButtonTemplate={nextButtonTemplate}
                backButtonTemplate={backButtonTemplate}
                finishButtonTemplate={finishButtonTemplate}>
                <FormWizard.TabContent title="إعدادات النشرة">
                  <Grid container spacing={1} columns={{ xs: 12, sm: 12, md: 12 }} justifyContent="center">
                    <Grid item xs={9} md={9}>
                      <TextField
                        value={senderEmail}
                        fullWidth
                        label="البريد الإلكتروني للمرسل"
                        onChange={(e) => updateDefaultReplyTo(e.target.value)}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        size="small"
                      />

                    </Grid>
                    <Grid item xs={2} md={2} style={{ display: 'flex' }}>

                      <Button variant="contained" color="inherit" size="medium" disabled={isSenderEmailButtonDisabled} className={classes.confirmCompanyEmailSender} onClick={createIdentityAWS}> تأكيد </Button>
                    </Grid>
                  </Grid>
                </FormWizard.TabContent>
                {/* Tabs should be validated */}
                <FormWizard.TabContent
                  title="إعدادات المظهر"
                  icon=""
                // isValid={checkValidateTab()}
                // validationError={errorMessages}
                >

                  <Grid container spacing={1} columns={{ xs: 1, sm: 1, md: 12 }} style={{
                    background: 'white',
                    padding: '20px',
                    borderRadius: '25px',
                    border: '3px solid #eee'
                  }}>
                    <Grid item md={12}>
                      <Grid container mt={2} spacing={1.5} columns={{ xs: 1, sm: 1, md: 12 }}>
                        <Grid item md={5}>
                          <Typography variant="body2" sx={{ fontWeight: 'bold', fontSize: "12px" }}>شعار الشركة</Typography>
                          <Typography variant="body2" sx={{ fontSize: "10px", fontWeight: 100, width: "250px" }}>سيتم عرض هذا الشعار في اي مكان لديك فيه عنصر يدل على الهوية مثل ترويسات وتذييل والحساب الرئيسي</Typography>
                        </Grid>
                        <Grid item md={5}>
                          <Typography variant="body2" sx={{ fontWeight: 'bold', fontSize: "12px" }}>شعار التذييل</Typography>
                          <Typography variant="body2" sx={{ fontSize: "10px", fontWeight: 100, width: "250px" }}>سيتم عرض هذا الشعار في اي مكان لديك فيه عنصر يدل على الهوية مثل ترويسات وتذييل والحساب الرئيسي</Typography>
                        </Grid>
                      </Grid>
                      <Grid container mt={2} spacing={1.5} columns={{ xs: 1, sm: 1, md: 12 }}>
                        <Grid item md={5}>
                          <ImageUploaderSetting image={logo} textImage={"+"} onChange={setLogo} />
                        </Grid>
                        <Grid item md={5}>
                          <ImageUploaderSetting image={footerLogo} textImage={"+"} onChange={setFooterLogo} />
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </FormWizard.TabContent>
                <FormWizard.TabContent
                  title="إعدادات المظهر"
                  icon=""
                // isValid={checkValidateTab()}
                // validationError={errorMessages}
                >

                  <Grid container spacing={1} columns={{ xs: 1, sm: 1, md: 12 }} style={{
                    background: 'white',
                    padding: '20px',
                    borderRadius: '25px',
                    border: '3px solid #eee'
                  }}>
                    <Grid item md={12}>
                      <Grid container mt={2} spacing={1.5} columns={{ xs: 1, sm: 1, md: 12 }}>
                        <Grid item md={5}>
                          <Typography variant="body2" sx={{ fontWeight: 'bold', fontSize: "12px" }}>اللون الرئيسي</Typography>
                          <Typography variant="body2" style={{ fontSize: "10px", fontWeight: 100 }}>عرض لون رئيسي للعناوين والتذييل</Typography>
                          <Button style={{ background: primaryColor?.hex, width: '50px', height: '60px', borderRadius: '50%', marginTop: '16px', border: '1px solid' }} size="large" variant="outlined" onClick={handlePrimaryColorClick}>  </Button>
                          {displayPrimaryColorPicker ? <div style={popover}>
                            <div style={cover} onClick={handlePrimaryColorClose} />
                            <ChromePicker
                              style={{ direction: 'ltr' }}
                              color={primaryColor}
                              onChange={(color) => setPrimaryColor(color)} />
                          </div> : null}
                        </Grid>
                        <Grid item md={5}>
                          <Typography variant="body2" sx={{ fontWeight: 'bold', fontSize: "12px" }}>اللون الثانوي</Typography>
                          <Typography variant="body2" style={{ fontSize: "10px", fontWeight: 100 }}>عرض لون ثانوي للعناوين والتذييل</Typography>
                          <Button style={{ background: secondaryColor?.hex, width: '50px', height: '60px', borderRadius: '50%', marginTop: '16px', border: '1px solid' }} size="large" variant="outlined" onClick={handleSecondaryColorClick}>  </Button>
                          {displaySecondaryColorPicker ? <div style={popover}>
                            <div style={cover} onClick={handleSecondaryColorClose} />
                            <ChromePicker
                              style={{ direction: 'ltr' }}
                              color={secondaryColor}
                              onChange={(color) => setSecondaryColor(color)} />
                          </div> : null}
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </FormWizard.TabContent>
                <FormWizard.TabContent title="إختيار الباقة" icon="">
                  <Grid container spacing={1} columns={{ xs: 1, sm: 1, md: 12 }} className={classes.packageContainer}>
                    {packages.map((item, index) => (
                      <Grid
                        item
                        md={3.8}
                        key={index}
                        className={`${classes.packageItem} ${selectedPackage === index ? classes.packageItemSelected : ''}`}
                        onClick={() => handlePackageClick(index)}
                      >
                        <Grid container spacing={1} columns={{ xs: 1, sm: 1, md: 12 }}>
                          <Grid item md={12} className={classes.packageItemTitle}>
                            <Typography variant="h3" sx={{ fontWeight: 'bold', fontSize: '14px' }}>{item.title}</Typography>
                          </Grid>
                          <Grid item md={12} className={classes.packageItemList}>
                            {item.type === 'select' && (
                              <FormControl sx={{ width: '100%', paddingRight: '25px' }} size="small">
                                <Select
                                  className={classes.packageItemSelectTag}
                                  labelId="demo-select-small-label"
                                  id="demo-select-small"
                                  value={selectedPackageSelect}
                                  onChange={handleChangePackageSelect}
                                >
                                  <MenuItem value="">
                                    <em>None</em>
                                  </MenuItem>
                                  <MenuItem value={10}>Ten</MenuItem>
                                  <MenuItem value={20}>Twenty</MenuItem>
                                  <MenuItem value={30}>Thirty</MenuItem>
                                </Select>
                              </FormControl>
                            )}
                            {item.details.map((detail, detailIndex) => (
                              <Typography key={detailIndex} variant="body2" sx={{ fontSize: '12px' }}>{detail}</Typography>
                            ))}

                          </Grid>
                        </Grid>
                      </Grid>
                    ))}

                  </Grid>


                </FormWizard.TabContent>
              </FormWizard>
              {/* add style */}

            </Grid>
          </Grid>
          <Snackbar
            open={snackBar.isOpen}
            autoHideDuration={6000}
            onClose={() => setSnackBar({ ...snackBar, isOpen: false })}
            message={snackBar.message}
          />
          <style>{`
        @import url("https://cdn.jsdelivr.net/gh/lykmapipo/themify-icons@0.1.2/css/themify-icons.css");
        .form-control {
            height: 36px;
            padding: 0.375rem 0.75rem;
            font-size: 1rem;
            font-weight: 400;
            line-height: 1.5;
            color: #495057;
            border: 1px solid #ced4da;
            border-radius: 0.25rem;
        }
            .react-form-wizard .wizard-progress-bar{
            float: right !important;
            }
.react-form-wizard .wizard-header{
padding: 40px;}
.react-form-wizard .wizard-icon-circle{
border: 3px solid #FF6150;
background-color: #FF6150;
}
.react-form-wizard .active .wizard-icon-circle{
border: 3px solid rgb(84, 210, 210);
background-color: rgb(84, 210, 210);
}
.react-form-wizard .wizard-icon-circle .wizard-icon-container{
display: none;}
      `}</style>
        </>

      );
    } else {
      return (
        <div style={{
          display: 'flex',
          flexDirection: 'column',
          minHeight: '100vh'
        }}>

          <Container maxWidth="100%" style={{ paddingRight: 0, flex: 1, background: "#F7F8F9" }}>
            <ProSidebarProvider>
              <Grid container spacing={1} columns={{ xs: 12, sm: 12, md: 12 }}>
                <Grid item xs={2} md={2}>
                  <SideBarMenu style={{ minHeight: 'calc(105vh + 100px)' }} />
                </Grid>
                <Grid item xs={10} md={10}>
                  <Grid container columns={{ xs: 1, sm: 1, md: 12 }} style={{ width: '120%', paddingRight: '20px', justifyContent: 'space-between', minHeight: 'calc(105vh + 100px)', marginBottom: '50px' }}>
                    <Grid item xs={1} sm={1} md={10}>
                      <Grid container columns={{ xs: 1, sm: 1, md: 12 }}>
                        <Grid item md={12} mt={7}>
                          <Navbar />
                        </Grid>
                        <Grid item md={12} mt={6} style={{ minHeight: 'calc(105vh - 170px)' }}>
                          {children}
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </ProSidebarProvider>
          </Container>
        </div>

      );
    }
  }

};
const dispatchData = (dispatch) => {
  return {
      updateCompanyData: (data) => { dispatch({ type: 'UPDATE_COMPANY_DATA', data: data }) }
  }
}
export default connect(null, dispatchData)(DashboardContainer);
// export default DashboardContainer;
