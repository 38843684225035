import React, { useState } from 'react';
import { useHistory } from "react-router-dom";
import {
	Avatar,
	Button,
	TextField,
	Link,
	Paper,
	Box,
	Grid,
	Typography,
	Stack,
	Container,
	Alert,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { getAuth, fetchSignInMethodsForEmail, signInWithEmailAndPassword, signOut, setPersistence, onAuthStateChanged } from 'firebase/auth';
import { doc, addDoc, deleteDoc, getDoc, getFirestore, getDocs, documentId, setDoc, collection, query, where } from 'firebase/firestore';
import { connect } from 'react-redux';
import firebase, { initializeApp } from 'firebase/app';
import backgroundImage from '../Public/assets/img/mujaz/mujaz-bg.jpg';

const errorMessages = {
	'auth/wrong-password': 'رمز مرور خاطئ',
	'auth/internal-error': 'خطأ داخلي',
	'auth/invalid-user-token': 'رمز المستخدم غير صالح',
	'auth/invalid-verification-code': 'رمز التحقق غير صالح',
	'auth/invalid-email': 'بريد إلكتروني خاطئ',
	'auth/timeout': 'نفذ الوقت',
	'auth/user-not-found': 'بريد إلكتروني غير مسجل'
};

const Login = (props) => {
	const history = useHistory();
	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');
	const [errorMessage, setErrorMessage] = useState('');
	const [isLoading, setIsLoading] = useState(false);
	let company = '';
	let compId = '';
	let compData = '';
	let loggedInUser = {};
	const auth = getAuth();
	const db = getFirestore();

	const firebaseConfigSecondary = {
		apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
		authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
		projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
		storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
		messagingSenderId: process.env.REACT_APP_FIREBASE_MESSEGING_SENDER_ID,
		appId: process.env.REACT_APP_FIREBASE_APP_ID,
		measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
	};
	// Initialize 2nd Firebase app
	const secondaryApp = initializeApp(firebaseConfigSecondary, "secondary");
	const secondaryAuth = getAuth(secondaryApp);

	const getCompanyRef = async (username, uid) => {
		const userRef = collection(db, "Users");
		const qUser = query(userRef, where('uid', "==", uid));
		let _data = await getDocs(qUser);
		_data.forEach((user) => {
			company = user.data().company;
			loggedInUser = user.data()
			// {
			// 	company: user.data().company,
			// 	email: user.data().email,
			// 	logo: user.data().logo,
			// 	name: user.data().name,
			// 	responsibility: user.data().responsibility,
			// 	uid: user.data().uid
			// }
		})


		const companyRef = collection(db, "Companies");
		// const q = query(companyRef, where("personalProfile.personalData.username", "==", username));
		const q = query(companyRef, where("personalProfile.companyData.company", "==", company));
		let data = await getDocs(q);
		data.forEach((comp) => {
			compData = comp.data();
			compId = comp.id;
		})

		props.setCompany(company, compId, compData, loggedInUser);
	}
	const onSubmit = async () => {
		setErrorMessage('');
		setIsLoading(true);
		try {
			const userCredential = await signInWithEmailAndPassword(secondaryAuth, email, password);
			if (!userCredential.user.emailVerified) {
				setErrorMessage('error.message');
				await signOut(auth); // sign out the unverified user
				throw new Error('Please verify your email to log in.');
			} else {
				//check if user has enabled 2FA
				const userRef = collection(db, "Users");
				const q = query(userRef, where("uid", "==", userCredential.user.uid));
				const querySnapshot = await getDocs(q);
				// return;
				if (!querySnapshot.empty) {
					if (querySnapshot.docs[0].data()._2fa?.enabled) {
						history.push("/verify-2fa", {
							email: email,
							password: password,
							secret: querySnapshot.docs[0].data()._2fa?.secret
						});
						return;
					} else {
						//login user if 2fa is not enabled/activated
						await signInWithEmailAndPassword(auth, email, password)
							.then((userCredential) => {
								// Signed in
								const user = userCredential.user;
								// setPersistence(auth, 'SESSION');
								getCompanyRef(user.email, user.uid);
								setIsLoading(false);
							})
							.catch((error) => {
								setErrorMessage(errorMessages[error.code]);
								setIsLoading(false);
							});
					}
				} else {
					setErrorMessage(errorMessages['auth/user-not-found']);
					setIsLoading(false);
				}

			}

		} catch (error) {
			setErrorMessage(errorMessages[error.code]);
			setIsLoading(false);
			// throw new Error('Failed to sign in. Please check your email and password and try again.');
		}
		// signInWithEmailAndPassword(auth, email, password)
		// 	.then((userCredential) => {
		// 		// Signed in
		// 		const user = userCredential.user;
		// 		console.log(user);
		// 		if (!user.emailVerified) {
		// 			signOut(auth); // sign out the unverified user
		// 			throw new Error('Please verify your email to log in.');
		// 		}
		// 		// setPersistence(auth, 'SESSION');
		// 		getCompanyRef(user.email, user.uid);
		// 		setIsLoading(false);
		// 	})
		// 	.catch((error) => {
		// 		setErrorMessage(errorMessages[error.code]);
		// 		setIsLoading(false);
		// 	});
	};

	return (
		<>
			<section className="wrapper image-wrapper bg-overlay bg-overlay-light-300 text-white"
				data-image-src="../Public/assets/img/mujaz/mujaz-bg.jpg"
				style={{ backgroundImage: `url(${backgroundImage})` }}
			>
				<div className="container pt-17 pb-20 pt-md-19 pb-md-21 text-center">
					<div className="row">
						<div className="col-lg-8 mx-auto">
							<h1 className="display-1 mb-3">تسجيل الدخول</h1>
							<nav className="d-inline-block" aria-label="breadcrumb">
								<ol className="breadcrumb">
									<li className="breadcrumb-item white-text"><a href="/" className='white-text'>الرئيسية</a></li>
									<li className="breadcrumb-item active white-text" aria-current="page">تسجيل الدخول</li>
								</ol>
							</nav>

						</div>

					</div>

				</div>
			</section>

			<section className="wrapper bg-light">
				<div className="container pb-14 pb-md-16">
					<div className="row">
						<div className="col-lg-7 col-xl-6 col-xxl-5 mx-auto mt-n20">
							<div className="card">
								<div className="card-body p-11 text-center">

									<Grid container spacing={2} columns={{ xs: 1, sm: 1, md: 1 }}>
										<Grid item xs={1} sm={1} md={1}>
											<Typography variant='body2' className="lead mb-6 text-end">الرجاء إدخال عنوان البريد الإلكتروني الخاص بك</Typography>
											{Boolean(errorMessage) && (
												<Alert variant="standard" color="error" style={{ width: '100%' }}>
													<Typography>{errorMessage}</Typography>
												</Alert>
											)}
										</Grid>
										<Grid item xs={1} sm={1} md={1}>
											<TextField
												margin="normal"
												fullWidth
												id="email"
												label="البريد الالكتروني"
												autoComplete="email"
												value={email}
												onChange={({ target: { value } }) => setEmail(value)}
											/>
										</Grid>
										<Grid item xs={1} sm={1} md={1}>
											<TextField
												margin="normal"
												fullWidth
												label="كلمة السر"
												type="password"
												id="password"
												autoComplete="current-password"
												value={password}
												onChange={({ target: { value } }) => setPassword(value)}
											/>
										</Grid>
										<Grid item xs={1} sm={1} md={1}>
											<LoadingButton
												// className="btn btn-primary rounded-pill btn-login w-100 mb-2"
												className="btn btn-primary btn-login w-100 mb-2"
												variant="contained"
												onClick={() => onSubmit()}
												loading={isLoading}
												disabled={isLoading}
											>تسجيل الدخول</LoadingButton>
										</Grid>
									</Grid>
									<Grid container>
										<Grid item xs>
											<Link href="/forget_password" variant="body2" style={{ textDecoration: 'none' }} className="hover">
												نسيت كلمة المرور؟
											</Link>
										</Grid>
										<Grid item xs>
											<Link href="/signup" variant="body2" style={{ textDecoration: 'none' }} className="hover">
												تسجيل حساب جديد
											</Link>
										</Grid>
									</Grid>
									{/* <p className="mb-0">ليس لديك حساب؟ <a href="./signup.html" className="hover">تسجيل حساب جديد</a></p> */}
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
		</>
	);
};

const dispatchCompany = (dispatch) => {
	return {
		setCompany: (company, id, data, loggedIn) => { dispatch({ type: 'SET_COMPANY', value: company, id: id, data: data, loggedIn: loggedIn }) }
	}
}

export default connect(null, dispatchCompany)(Login);
