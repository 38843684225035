import React, { useEffect, useState } from 'react';
import {
    Button,
    CircularProgress,
    Container,
    Divider,
    Grid,
    Paper,
    Stack,
    IconButton,
    TextField,
    Typography,
    Snackbar,
} from '@mui/material';
import { makeStyles } from "tss-react/mui";
import { Delete, Edit } from '@material-ui/icons';
import LoadingButton from '@mui/lab/LoadingButton';
import { generateIntroCard, generateKey, generateWrappedEmail, swapArrayItems } from '../../utils';
import { doc, addDoc, deleteDoc, getDoc, getFirestore, getDocs, setDoc, collection, query, where } from 'firebase/firestore';
import SplitButton from '../../atoms/SplitButton';
import IntroCard from '../../components/IntroCard';
import HTMLViewer from '../../components/HTMLViewer';
import NewsCardContainer from '../../components/NewsCardContainer';
import SideBarMenu from '../../components/SideBarMenu';
import logo from '../../img/pic-03.jpg';
import Footer from '../../components/Footer';
import AddEditCategory from '../../components/AddEditCategory';
import { confirm } from "react-confirm-box";
import { connect } from 'react-redux';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import trash from '../../img/icons/trash.svg'

const Categories = (props) => {
    const [isLoading, setIsLoading] = useState(true);
    const [items, setItems] = useState([]);
    const [newCatVisible, setNewCatVisible] = useState(false);
    const [CatVisible, setCatVisible] = useState([]);
    const [ConfirmationDialogStatus, setConfirmationDialogStatus] = useState(false);
    const [snackBar, setSnackBar] = useState({
        isOpen: false,
        message: '',
    });
    const db = getFirestore();
    const { company, data } = props;
    const useStyles = makeStyles()(
        () => ({
            main: {
                width: '100%',
                '@media only screen and (min-width: 1200px)': {
                    width: '100%',
                },
            },
            container: {
                boxSizing: 'border-box',
                display: 'flex',
                flexWrap: 'nowrap',
                width: '100%',
                flexDirection: 'row',
                background: '#fefefe',
                justifyContent: 'space-between',
                alignItems: 'flex-start',
            },
            content: {
                paddingRight: '20px',
            },
            welomeText: {
                color: '#ff6633',
                fontWeight: 700,
            },
            paper: {
                display: 'flex',
                flexWrap: 'nowrap',
                alignItems: 'center',
                flexDirection: 'column',
                marginTop: 210,
            },
            paperList: {
                display: 'flex',
                justifyContent: 'space-between',
                flexDirection: 'row',
                alignItems: 'center',
                padding: '0 30px',
                minHeight: 60,
                marginBottom: 15
            },
            editDeleteBtns: {
                display: 'flex',
            },
            btns: {
                color: '#ff6633'
            },
            textContainer: {
                width: '100%',
                margin: '20px 0',
            },
            textPaper: {
                width: '100%',
                padding: '40px 30px 0px 30px',
                display: 'flex',
                flexDirection: 'column',
                boxShadow: '2px 2px 10px #ccc',
            },
            submitBtnGrid: {
                display: 'flex',
                flexDirection: 'row-reverse',
                margin: '20px 0',
            },
            submitBtn: {
                background: '#f63',
                color: '#FFF',
                padding: '5px 25px',
                borderRadius: '30px',
                ':hover': {
                    background: '#FFF',
                    color: '#f63',
                }
            },
            addCatForm: {
                display: 'flex',
                flexDirection: 'column',
            },
            confirmContainer: {
                position: 'fixed',
                left: '50%',
                top: '50%',
                transform: 'translate(-50%, -50%)',
                maxWidth: '500px'
            },
            confirmText: {
                '& h4': {
                    fontFamily: 'Tajawal,sans-serif',
                }

            },
            confirmAction: {
                display: 'flex',
                flexWrap: 'nowrap',
                justifyContent: 'space-between',
                padding: '50px 0px 0px 0px',
                '&>button': {
                    borderRadius: 10,
                    // boxShadow: '0px 3px 6px #00000026',
                    fontFamily: 'Tajawal,sans-serif',

                },
                '&>button:first-of-type': {
                    background: '#FFF',
                    color: '#ff6633',
                    width: '100px',
                    border: '1px solid'
                },
                '&>button:last-child': {
                    background: '#ff6633',
                    color: '#FFF',
                    width: '100px',
                }
            },
            tableHeader: {
                // boxShadow: '1px 2px 5px #ddd',
                borderRadius: '10px',
                marginBottom: '20px',
                marginTop: '25px',
                // paddingLeft:'50px'
                background: '#eee',
            },

            confirmBox: {
                position: 'fixed',
                left: '50%',
                top: '50%',
                transform: 'translate(-50%, -50%)',
                maxWidth: '500px'
            },
            submitBtn: {
                background: '#f27021 !important',
                color: '#FFF !important',
                fontFamily: 'Tajawal, sans-serif !important',
                borderRadius: '10px'
            },
            cancelBtn: {
                background: '#FFF !important',
                color: '#f27021 !important',
                border: '1px solid #f27021 !important',
                fontFamily: 'Tajawal, sans-serif !important',
                borderRadius: '10px'
            },
            btns: {
                color: '#f27021'
            }

        })
    );

    const { classes } = useStyles();

    const options = {
        render: (message, onConfirm, onCancel) => {
            return (
                <Grid container spacing={4} columns={{ xs: 1, sm: 1, md: 12 }} className={classes.confirmBox}>
                    <Grid item xs={1} sm={1} md={12}>
                        <Paper elevation={0} style={{ padding: '30px', borderRadius: '10px' }}>
                            <Grid container spacing={4} columns={{ xs: 1, sm: 1, md: 12 }}>
                                <Grid item xs={1} sm={1} md={12}>
                                    <Typography variant="h5">{message}</Typography>
                                </Grid>
                                <Grid item xs={1} sm={1} md={12}>
                                    <Grid container spacing={4} columns={{ xs: 1, sm: 1, md: 12 }}>
                                        <Grid item xs={1} sm={1} md={6} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                            <Button variant="outlined" className={classes.cancelBtn} size="small" onClick={onCancel}> تراجع </Button>
                                        </Grid>
                                        <Grid item xs={1} sm={1} md={6} style={{ display: 'flex', justifyContent: 'flex-start' }}>
                                            <Button variant="contained" className={classes.submitBtn} size="small" onClick={onConfirm}> تأكيد </Button>
                                        </Grid>
                                    </Grid>

                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>
                </Grid>

            );
        }
    };
    const getDetails = async () => {
        const categoriesRef = collection(db, "Categories");
        const q = query(categoriesRef, where("company", "==", company));
        let data = await getDocs(q);
        data.forEach((doc) => {
            setItems(items => [...items, {
                id: doc.id,
                company: doc.data().company,
                name: doc.data().name,
            }]);
            setCatVisible(prev => [...prev, false]);
        });
        setIsLoading(false);
    };
    const handleCatVisible = (index) => {
        let arr = [...CatVisible];
        arr[index] = !arr[index];
        setCatVisible(arr);
    };

    const handleAddCategory = async (name, id, index) => {
        if (id === '') {
            console.log(name);
            const docRef = await addDoc(collection(db, "Categories"), {
                name: name,
                company: company
            }).then((data) => {
                setItems(items => [...items, {
                    id: data.id,
                    company: company,
                    name: name,
                }]);
                setNewCatVisible(prev => !prev);
                setSnackBar({
                    isOpen: true,
                    message: 'تم إضافة الفئة بنجاح',
                });
            });
        } else {
            await setDoc(doc(db, 'Categories', id), {
                name: name,
                company: company
            }).then(() => {
                let newItemObj = items.map(obj => {
                    if (obj.id == id) {
                        return {
                            id: id,
                            name: name,
                            company: company
                        }
                    }
                    return obj;
                });
                setItems(newItemObj);
                handleCatVisible(index);
                setSnackBar({
                    isOpen: true,
                    message: 'تم تعديل الفئة بنجاح',
                });
            });
        }

    }

    const handleDeleteCategory = async (id) => {
        const result = await confirm("هل حقا تريد حذف هذه الفئة؟", options);
        if (result) {
            await deleteDoc(doc(db, "Categories", id)).then(() => {
                console.log('deleted: ' + id);
                setItems(items.filter(item => item.id !== id));
                setSnackBar({
                    isOpen: true,
                    message: 'تم مسج الفئة بنجاح',
                });
            });
        }
    }

    useEffect(() => {
        getDetails();
    }, []);



    return (
        <>
            <Grid container sx={{ justifyContent: 'center', alignItems: 'center' }} mt={4} columns={{ lg: 12 }}>
                <Grid item sm={8} md={10} lg={10}>
                    <Paper elevation={0}>
                        <Grid container spacing={2} style={{ flexDirection: 'row', paddingRight: '50px', paddingTop: '25px' }}>
                            <Grid item>
                                <Typography variant="body2" sx={{ fontWeight: 'bold' }}> الفئات</Typography>
                                <Typography variant="body2" sx={{ fontSize: '12px' }}> قم باضافه او تعديل او حذف الفئات</Typography>
                            </Grid>
                        </Grid>
                        <Grid container style={{ flexDirection: 'row-reverse', paddingLeft: '50px' }}>
                            <Grid item>
                                {/* <CircularProgress style={{ color: '#f63' }} /> */}
                                <Button
                                    size='small'
                                    style={{ backgroundColor: '#FF6150', borderRadius: '7px' }}
                                    variant="contained"
                                    onClick={() => setNewCatVisible(prev => !prev)}>
                                    عرض
                                </Button>
                            </Grid>
                        </Grid>

                        {newCatVisible && <AddEditCategory
                            name=""
                            id=""
                            buttonName="إضافة"
                            handleAddCategory={handleAddCategory}
                            onCloseIt={() => {
                                setTimeout(() => { setNewCatVisible(false); }, 500);
                                // setListUserVisible(true);
                                // setUserVisible(true);
                            }} />}
                        <TableContainer component={Paper} sx={{ boxShadow: 'none', padding: '50px' }}>
                            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                <TableHead sx={{ background: '#F5F6FA', borderRadius: '10px', overflow: 'hidden' }}>
                                    <TableRow sx={{
                                        background: '#F5F6FA',
                                        '&:first-of-type': {
                                            borderTopLeftRadius: '10px',
                                            borderTopRightRadius: '10px',
                                        }
                                    }}>
                                        <TableCell sx={{ borderTopLeftRadius: '10px', borderBottomLeftRadius: '10px', borderBottom: "none" }}>
                                            <Typography xs={3} variant="p" >
                                                الفئة
                                            </Typography>
                                        </TableCell>
                                        <TableCell align="right" sx={{ borderTopRightRadius: '10px', borderBottomRightRadius: '10px', borderBottom: "none" }}>
                                            <Typography xs={3} variant="p" >
                                                إجراءات
                                            </Typography>
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {items?.map((row, index) => {
                                        return (
                                            <>
                                                <TableRow
                                                    key={index}
                                                    index={index}
                                                // sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                >
                                                    <TableCell component="th" scope="row" sx={{ borderBottom: "none" }}>
                                                        {row.name}
                                                    </TableCell>
                                                    <TableCell align="right" sx={{ borderBottom: "none" }}>
                                                        <IconButton size='small' aria-label="edit" className={classes.btns} onClick={() => handleCatVisible(index)}>
                                                            <Edit fontSize='small' />
                                                        </IconButton>
                                                        <IconButton size='small' aria-label="delete" className={classes.btns} onClick={() => handleDeleteCategory(row.id)}>
                                                            <img src={trash} alt="Trash Logo" style={{ width: '20px', height: '20px', }} />
                                                        </IconButton>
                                                    </TableCell>
                                                </TableRow>
                                                {CatVisible[index] && <AddEditCategory
                                                    name={row.name}
                                                    id={row.id}
                                                    index={index}
                                                    handleAddCategory={handleAddCategory}
                                                    buttonName="حفظ" />}
                                            </>

                                        )
                                    }
                                    )}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <Snackbar
                            open={snackBar.isOpen}
                            autoHideDuration={6000}
                            onClose={() => setSnackBar({ ...snackBar, isOpen: false })}
                            message={snackBar.message}
                        />
                    </Paper>
                </Grid>
            </Grid>

        </>

    );
};
const mapStateToProps = (state) => {
    return state
}


export default connect(mapStateToProps)(Categories);
