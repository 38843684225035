const initState = {
    company: '',
    id: '',
    data: '',
    loggedInUser: ''
}

const rootReducer = (state = initState, action) => {
    if (action.type === 'RESET_STATE') {
        return initState;
    }
    if (action.type === 'SET_COMPANY') {
        return {
            company: action.value,
            id: action.id,
            data: action.data,
            loggedInUser: action.loggedIn
        }
    }
    if (action.type === 'UPDATE_USER') {
        // console.log(action.value);
        return {
            ...state,
            loggedInUser: action.value
        }
    }
    if (action.type === 'SET_DATA') {
        return {
            ...state,
            data: action.data
        }
    }
    if (action.type === 'UPDATE_QUOTA') {
        return {
            ...state,
            data: {
                ...state.data,
                settings: {
                    ...state.data?.settings,
                    package: {
                        ...state.data?.settings?.package,
                        sentEmails: action.emailsSent
                    }
                }
            }
        }
    }
    if (action.type === 'UPDATE_COMPANY_DATA') {
        return {
            ...state,
            data: {
                ...state.data,
                settings: {
                    ...state.data?.settings,
                    senderSettings: {
                        ...state.data?.settings?.senderSettings,
                        defaultFrom: action?.data?.companyName + ' <' + action?.data?.senderEmail + '>',
                        defaultReplyTo: action?.data?.senderEmail,
                        email: action?.data?.senderEmail,
                        name: action?.data?.companyName,
                    }
                }
            }
        }
    }
    return state;
}


export default rootReducer;