import React from 'react';
import { useHistory } from 'react-router-dom';
import { AppBar, Toolbar, Button, Stack, Container, Grid, Paper } from '@mui/material';
import { getAuth, signOut } from 'firebase/auth';
import { Link } from 'react-router-dom';
import PublicNavbar from './PublicNavbar';
import DeleteIcon from '@mui/icons-material/Delete';
import NewsletterIcon from '@mui/icons-material/Newspaper';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import DashboardLogo from '../img/logo-01.png';
import ProfileLogo from '../img/icons/1-13.png';


import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import PersonAdd from '@mui/icons-material/PersonAdd';
import Settings from '@mui/icons-material/Settings';
import Logout from '@mui/icons-material/Logout';


import { makeStyles } from "tss-react/mui";
import { connect } from 'react-redux';

//import { BiLogOut } from "react-icons/bi";
import logoutIcon from "../img/icons/logoutIcon.png"
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import LogoutIcon from '@mui/icons-material/Logout';
import PermIdentityIcon from '@mui/icons-material/PermIdentity';

const Navbar = (props) => {
	const auth = getAuth();
	const history = useHistory();
	const useStyles = makeStyles()(
		() => ({
			container: {
				display: 'flex',
				flexDirection: 'row',
				alignContent: 'center',
				justifyContent: 'space-around',
				alignItems: 'center',
			},
			logo: {
				marginTop: 10,
				marginBottom: 10,


			},
			singleBtnContainer: {
				minWidth: 700,
			},
			singleBtn: {
				backgroundColor: '#ff6633',
				color: '#FFF',
				boxShadow: '0px 3px 6px #00000026',
				':hover': {
					backgroundColor: '#FFF',
					color: '#ff6633',
				}

			},
			signoutBtn: {
				backgroundColor: '#fff',
				color: '#ff6633',
				borderRadius: '30px',
				boxShadow: '0px 3px 6px #00000026',
				':hover': {
					backgroundColor: '#ff6633',
					color: '#FFF',
				},

			},
			btnProfileLogo: {
				marginRight: '20px',
				padding: 0,
			},
			profileLogo: {
				width: '50px'
			},
			AppBar: {
				boxShadow: '0px 3px 6px #dfdfdf',
			}

		})
	);

	const { classes } = useStyles();

	const { responsibility, name } = props.loggedInUser;
	const [anchorEl, setAnchorEl] = React.useState(null);
	const open = Boolean(anchorEl);
	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};
	const handleClose = () => {
		setAnchorEl(null);
	};
	const logoutUser = () => {
		props.restState();
		signOut(auth);
	}
	return (
		<>
			{!Boolean(auth.currentUser) && <PublicNavbar />}
			{/* <AppBar color="secondary" className={classes.AppBar} position="static" sx={{ mb: 5 }}> */}
			{/* <Toolbar variant='dense' > */}
			{/* <Grid container spacing={3} className={classes.container}>
						<Grid item>
							<RouterLink to="/dashboard">
								<img src={DashboardLogo} className={classes.logo} />
							</RouterLink>
						</Grid>

						{responsibility != 'superadmin' &&
							<Grid item className={classes.singleBtnContainer}>
								<Button
									variant="contained"
									size="large"
									className={classes.singleBtn}
									endIcon={<NewsletterIcon fontSize="large" />}
									component={RouterLink} to="/createnewsletter">
									إنشاء نشرة جديدة
								</Button>
							</Grid>
						}

						<Grid item>
							<Button
								className={classes.signoutBtn}
								variant="contained"
								size="small"
								endIcon={<ExitToAppIcon />}
								onClick={() => {
									signOut(auth);
								}}
							>
								تسجيل خروج
							</Button>
							{responsibility != 'superadmin' &&
								<Button
									className={classes.btnProfileLogo}
									component={RouterLink} to="/profile"
									size="small"
								>
									<img src={ProfileLogo} className={classes.profileLogo} />
								</Button>
							}
						</Grid>



					</Grid> */}

			{/* </Toolbar> */}
			<Paper elevation={0}>
				<Grid container spacing={1} style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'flex-end', padding: '10px 10px 10px 10px' }}>
					<Grid item>
						{/* <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center', padding: '4px' }}>
							<Tooltip title="Your Name">
								<Typography onClick={handleClick} variant='caption'>{name}</Typography>
							</Tooltip>
							<Tooltip title="Account settings">
								<IconButton
									onClick={handleClick}
									size="small"
									sx={{ ml: 2 }}
									aria-controls={open ? 'account-menu' : undefined}
									aria-haspopup="true"
									aria-expanded={open ? 'true' : undefined}
								>

									<Avatar sx={{ width: 32, height: 32 }}>{name?.charAt(0)}</Avatar>
								</IconButton>
							</Tooltip>
						</Box> */}
						{/* <Menu
							anchorEl={anchorEl}
							id="account-menu"
							open={open}
							onClose={handleClose}
							onClick={handleClose}
							PaperProps={{
								elevation: 0,
								sx: {
									overflow: 'visible',
									filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
									mt: 1.5,
									'& .MuiAvatar-root': {
										width: 32,
										height: 32,
										ml: -0.5,
										mr: 1,
									},
									'&:before': {
										content: '""',
										display: 'block',
										position: 'absolute',
										top: 0,
										right: 14,
										width: 10,
										height: 10,
										bgcolor: 'background.paper',
										transform: 'translateY(-50%) rotate(45deg)',
										zIndex: 0,
									},
								},
							}}
							transformOrigin={{ horizontal: 'right', vertical: 'top' }}
							anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
						>
							<MenuItem onClick={() => {
								history.push('/profile');
							}}>
								<Avatar color='primary' /> <span style={{ fontSize: '16px' }}>حسابي</span>
							</MenuItem>
							<Divider />
							
							<MenuItem onClick={() => { logoutUser() }}>
								<ListItemIcon>
									<Logout fontSize="small" color='primary' />
								</ListItemIcon>
								<span style={{ fontSize: '16px' }}>تسجيل الخروج</span>
							</MenuItem>
						</Menu> */}
						<NotificationsNoneIcon />
					</Grid>
					<Grid item>
						<PermIdentityIcon onClick={() => {
							history.push('/profile');
						}} />

					</Grid>
					<Grid item>
						<LogoutIcon onClick={() => { logoutUser() }} />

					</Grid>
				</Grid>
			</Paper>
		</>
	);
};


const mapStateToProps = (state) => {
	return state
}
const dispatchUser = (dispatch) => {
	return {
		restState: () => { dispatch({ type: 'RESET_STATE' }) }
	}
}
export default connect(mapStateToProps, dispatchUser)(Navbar);
