import { Divider, Grid, Stack, TextField, Button, Autocomplete, Box, Typography, InputAdornment, Snackbar } from '@mui/material';
import React, { useState, useEffect, useRef } from 'react';
import RichTextEditor from '../../atoms/RichTextEditor';
import ImageUploader from '../../components/ImageUploader';
import Footer from '../../components/Footer';
import SideBarMenu from '../../components/SideBarMenu';
import { makeStyles } from "tss-react/mui";
import { doc, addDoc, getDoc, collection, getFirestore, getDocs, setDoc, query, where } from 'firebase/firestore';
import HTMLViewer from '../../components/HTMLViewer';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { generateKey } from '../../utils';
import SearchIcon from '@mui/icons-material/Search';
import SaveIcon from '@mui/icons-material/Save';
import Preview from '../../components/Preview';
import { connect } from 'react-redux';
import sectionsIcon from '../../img/icons/1-09.png';


const NewsWithImage = (props) => {
	const [category, setCategory] = useState('');
	const [section, setSection] = useState('');
	const [title, setTitle] = useState('');
	const [body, setBody] = useState('');
	const [intro, setIntro] = useState('');
	const [items, setItems] = useState([]);
	const [image, setImage] = useState('');
	const [credits, setCredits] = useState('');
	const [alt, setAlt] = useState('');
	const [imageURL, setImageURL] = useState('');
	const [catOptions, setCatOptions] = useState([]);
	const [valueDate, setValueDate] = useState(new Date());
	const [snackBar, setSnackBar] = useState({
		isOpen: false,
		message: '',
	});
	const db = getFirestore();
	const previewCompRef = useRef();
	const { company, data } = props;
	const useStyles = makeStyles()(
		() => ({
			main: {
				width: '100%',
				'@media only screen and (min-width: 1200px)': {
					width: '100%',
				},
			},
			container: {
				boxSizing: 'border-box',
				display: 'flex',
				flexWrap: 'nowrap',
				width: '100%',
				flexDirection: 'row',
				background: '#fefefe',
				justifyContent: 'space-between',
				alignItems: 'flex-start',
			},
			welomeText: {
				color: '#ff6633',
				fontWeight: 700,
			},
			paper: {
				display: 'flex',
				flexWrap: 'nowrap',
				alignItems: 'center',
				flexDirection: 'column',
				marginTop: 210,
			},
			content: {
				background: '#EFF0F7',
				borderRadius: '25px',
				padding: '25px',
			}

		})
	);

	const { classes } = useStyles();

	const getDateId = (_company) => {
		return `${_company}_${parseInt(new Date().getDate()) + (new Date().getHours() < 5 ? 0 : 1)}-${(new Date().getMonth() + 1).toString().padStart(2, '0')
			}-${new Date().getFullYear()}`;
	};
	const PreviewFn = () => {
		setItems([{
			id: generateKey(),
			category: category,
			section: section,
			title: title,
			image: image,
			imageURL: imageURL,
			alt: alt,
			credits: credits,
			body: body,
			date: `${parseInt(new Date().getDate())}-${new Date().getMonth() + 1
				}-${new Date().getFullYear()}`
		}])

		previewCompRef.current.showPreview();
	}
	const convertDate = (_company, date) => {
		return `${_company}_${parseInt(date.getDate())}-${(new Date().getMonth() + 1).toString().padStart(2, '0')}-${date.getFullYear()}`;
	}

	const saveNewsWithImage = async () => {
		const docTitle = convertDate(company, valueDate);
		console.log(docTitle);
		let data = await getDoc(doc(db, 'NewsLibrary', docTitle));
		if (data.data()) {
			await setDoc(doc(db, 'NewsLibrary', docTitle), {
				News: data.data().News.concat({
					id: generateKey(),
					category: category,
					section: section,
					title: title,
					image: image,
					imageURL: imageURL,
					alt: alt,
					credits: credits,
					body: body,
					date: `${parseInt(new Date().getDate())}-${(new Date().getMonth() + 1).toString().padStart(2, '0')
						}-${new Date().getFullYear()}`
				}),
				Stocks: data.data().Stocks,
				Weather: data.data().Weather,
				Image: data.data().Image,
				Currency: data.data().Currency,
				data: data.data().data
			}).then((data) => {
				console.log('done update');
			});
		} else {
			await setDoc(doc(db, 'NewsLibrary', docTitle), {
				News:
					[
						{
							id: generateKey(),
							category: category,
							section: section,
							title: title,
							image: image,
							imageURL: imageURL,
							alt: alt,
							credits: credits,
							body: body,
							date: `${parseInt(new Date().getDate())}-${(new Date().getMonth() + 1).toString().padStart(2, '0')
								}-${new Date().getFullYear()}`
						}
					]
				,
				Stocks: '',
				Weather: '',
				Image: [],
				Currency: '',
				data: {
					date: `${parseInt(new Date().getDate())}-${(new Date().getMonth() + 1).toString().padStart(2, '0')
						}-${new Date().getFullYear()}`,
					company: company
				}
			}).then((data) => {
				console.log('done');
			});
		}
		setSnackBar({
			isOpen: true,
			message: 'تم الحفظ في مكتبة الفئات',
		});
	}

	const getCategories = async () => {
		const categoriesRef = collection(db, "Categories");
		const q = query(categoriesRef, where("company", "==", company));
		let data = await getDocs(q);
		data.forEach((doc) => {
			// console.log(doc.data());
			setCatOptions(catOptions => [...catOptions, doc.data().name]);
		});
	}

	const handleDateChange = (newValue) => {
		setValueDate(newValue);
	}
	useEffect(() => {
		getCategories();
		console.log(data);
	}, []);
	useEffect(() => {
		if (items.length > 0) {
			console.log(items);
			previewCompRef.current.showPreview();
		}
	}, [items]);

	const handleImageChange = (newImageURL) => {
		setImage(newImageURL);
		setImageURL(newImageURL);
	};

	return (
		<>
			<Grid container spacing={3} mt={1} style={{ marginRight: 0, width: '100%' }}>
				<Grid item xs={6} className={classes.content} >
					<Stack spacing={3} direction="column">
						<Stack direction="column" spacing={3}>
							<Autocomplete
								options={catOptions}
								disableClearable
								onChange={(e, cat) => setCategory(cat)}
								renderInput={(params) =>
									<TextField
										{...params}
										label="الفئة"
										InputProps={{
											...params.InputProps,
											// endAdornment: (
											// 	<img src={sectionsIcon} alt="Custom Icon" style={{ width: '40px', height: '40px', color: '#FF6150' }} />
											// ),
										}}
									/>}
								sx={{
									mb: 3,
									'& .MuiInputBase-root': {
										fontSize: '0.7rem', // Smaller font size
									},
									'& .MuiInputLabel-root': {
										fontSize: '0.7rem', // Smaller label font size
									},
									'& .MuiOutlinedInput-root': {
										borderRadius: '15px'
									},
									"& fieldset": {
										border: 'none',
									},
								}}
							/>
							<LocalizationProvider dateAdapter={AdapterDateFns}>
								<DesktopDatePicker
									placeholder="التاريخ"
									inputFormat="MM/dd/yyyy"
									value={valueDate}
									onChange={handleDateChange}
									renderInput={(params) =>
										<TextField
											{...params}
											sx={{
												'& .MuiSvgIcon-root': {
													color: '#FF6150', // Change the color of the icon
												},
												'& .MuiInputBase-root': { // Replace with your actual class name
													height: '50px',
													borderRadius: '15px',
													display: 'flex',
													alignItems: 'center',
												},
												'& .MuiInputBase-input': {
													textAlign: 'center',
													alignSelf: 'baseline',
												},
												"& fieldset": {
													border: 'none',
												},
											}} />}
								/>
							</LocalizationProvider>
							<TextField
								fullWidth
								value={title}
								onChange={({ target: { value } }) => setTitle(value)}
								variant="outlined"
								label="العنوان الرئيسي"
								placeholder="العنوان الرئيسي"
								sx={{
									'& .MuiInputBase-root': {
										fontSize: '0.7rem', // Smaller font size
									},
									'& .MuiInputLabel-root': {
										fontSize: '0.7rem', // Smaller label font size
									},
									'& .MuiOutlinedInput-root': {
										borderRadius: '15px'
									},
									"& fieldset": {
										border: 'none',
									},
								}}
							/>
						</Stack>

						<Grid container alignItems="center" columns={{ sm: 1, md: 12 }}>
							<Grid item sm={4} md={2}>
								<ImageUploader textImage={'إضافة صورة'} image={image} onChange={handleImageChange} />
							</Grid>
							<Grid item sm={8} md={10}>
								<TextField
									fullWidth
									value={imageURL}
									onChange={({ target: { value } }) => setImageURL(value)}
									variant="outlined"
									label="رابط الصورة"
									placeholder="رابط الصورة"
									inputProps={
										{ readOnly: true, }
									}
									sx={{
										'& .MuiInputBase-root': {
											fontSize: '0.7rem', // Smaller font size
										},
										'& .MuiInputLabel-root': {
											fontSize: '0.7rem', // Smaller label font size
										},
										'& .MuiOutlinedInput-root': {
											borderRadius: '15px'
										},
										"& fieldset": {
											border: 'none',
										},
									}}
								/>
							</Grid>

						</Grid>
						<Stack direction="row" spacing={1}>
							<TextField
								fullWidth
								value={credits}
								onChange={({ target: { value } }) => setCredits(value)}
								variant="outlined"
								label="المصدر"
								placeholder="المصدر"
								sx={{
									'& .MuiInputBase-root': {
										fontSize: '0.7rem', // Smaller font size
									},
									'& .MuiInputLabel-root': {
										fontSize: '0.7rem', // Smaller label font size
									},
									'& .MuiOutlinedInput-root': {
										borderRadius: '15px'
									},
									"& fieldset": {
										border: 'none',
									},
								}}
							/>
							<TextField
								fullWidth
								value={alt}
								onChange={({ target: { value } }) => setAlt(value)}
								variant="outlined"
								label="الوصف"
								placeholder="الوصف"
								sx={{
									'& .MuiInputBase-root': {
										fontSize: '0.7rem', // Smaller font size
									},
									'& .MuiInputLabel-root': {
										fontSize: '0.7rem', // Smaller label font size
									},
									'& .MuiOutlinedInput-root': {
										borderRadius: '15px',
									},
									"& fieldset": {
										border: 'none',
									},
								}}
							/>

						</Stack>
						<RichTextEditor defaultValue={body} onChange={setBody} />
						<Grid container mt={4} columns={{ sm: 1, md: 12 }} justifyContent="space-between" alignItems="center">
							<Grid item xs={6} md={4} >
								<Button size='small' variant="outlined" style={{ color: '#FF6150', borderRadius: '15px', padding: '10px' }} endIcon={<SearchIcon />} onClick={PreviewFn}>معاينة</Button>
							</Grid>
							<Grid item xs={6} md={6} sx={{ display: 'grid' }}>
								<Button size='small' variant="contained" style={{ backgroundColor: '#FF6150', borderRadius: '15px', padding: '10px', }} endIcon={<SaveIcon />} onClick={saveNewsWithImage}>حفظ في مكتبة الفئات</Button>
							</Grid>
						</Grid>
					</Stack>
				</Grid>
				<Grid item xs={6} style={{ paddingTop: 0 }}>
					<Preview data={items} compData={data} intro={intro} ref={previewCompRef} />
				</Grid>

			</Grid>
			<Snackbar
				open={snackBar.isOpen}
				autoHideDuration={6000}
				onClose={() => setSnackBar({ ...snackBar, isOpen: false })}
				message={snackBar.message}
			/>
		</>
	);
};
const mapStateToProps = (state) => {
	console.log(state);
	return state
}

export default connect(mapStateToProps)(NewsWithImage);
