import React, { useEffect, useState } from 'react';
import {
    Button,
    CircularProgress,
    Container,
    Divider,
    Grid,
    Paper,
    Stack,
    IconButton,
    TextField,
    Typography,
    Snackbar,
} from '@mui/material';
import Box from '@mui/material/Box';
import { Delete, Edit } from '@material-ui/icons';
import LoadingButton from '@mui/lab/LoadingButton';
import { doc, addDoc, getDoc, getFirestore, getDocs, setDoc, collection, query, where } from 'firebase/firestore';
import Drawer from '@mui/material/Drawer';
import { makeStyles } from "tss-react/mui";
import { isEmpty } from 'lodash';



const AddEditCategory = ({ buttonName, name, id, index, handleAddCategory, onCloseIt }) => {
    const [isLoading, setIsLoading] = useState(true);
    const [catName, setCatName] = useState(name);
    const [open, setOpen] = useState(true)
    // const [visible, setVisible] = useState(false);
    const db = getFirestore();

    const useStyles = makeStyles()(
        () => ({
            main: {
                width: '100%',
                '@media only screen and (min-width: 1200px)': {
                    width: '100%',
                },
            },
            container: {
                boxSizing: 'border-box',
                display: 'flex',
                flexWrap: 'nowrap',
                width: '100%',
                flexDirection: 'row',
                background: '#fefefe',
                justifyContent: 'space-between',
                alignItems: 'flex-start',
            },
            content: {
                paddingRight: '20px',
            },
            welomeText: {
                color: '#ff6633',
                fontWeight: 700,
            },
            paper: {
                display: 'flex',
                flexWrap: 'nowrap',
                alignItems: 'center',
                flexDirection: 'column',
                marginTop: 210,
            },
            paperList: {
                display: 'flex',
                justifyContent: 'space-between',
                flexDirection: 'row',
                alignItems: 'center',
                padding: '0 30px',
                minHeight: 60,
                marginBottom: 15
            },
            editDeleteBtns: {
                display: 'flex',
            },
            btns: {
                color: '#ff6633'
            },
            textContainer: {
                width: '100%',
            },
            textPaper: {
                width: '100%',
                padding: '20px',
                display: 'flex',
                flexDirection: 'column',
                marginTop: '20px'
                // boxShadow: '2px 2px 10px #ccc',
            },
            submitBtnGrid: {
                display: 'flex',
                flexDirection: 'row-reverse',
                margin: '20px 0',
            },
            submitBtn: {
                background: '#f63',
                color: '#FFF',
                padding: '5px 25px',
                borderRadius: '30px',
                ':hover': {
                    background: '#FFF',
                    color: '#f63',
                }
            },
            addCatForm: {
                display: 'flex',
                flexDirection: 'column',
            },
            gridContainer: {
                width: 400,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',

            },
            item: {
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                width: '100%',
            },

        })
    );

    const { classes } = useStyles();
    const toggleDrawer = (newOpen) => () => {
        setOpen(newOpen);
        onCloseIt();
    };

    return (
        <>
            <Drawer open={open} anchor={"right"} onClose={toggleDrawer(false)}  >
               
                <Box
                    sx={{
                        display: 'flex',
                       justifyContent: 'center',
                       alignItems: 'center',
                        height: '100vh',
                        width: '500px',
                    }}
                >
                    <Grid container sx={{ width: '100%', ml: 8, mr: 8, }} direction='column' justifyContent="center" alignItems="center">
                        <Grid item  className={classes.textContainer}>
                            <Typography variant="body2" sx={{ fontSize: "12px" }}>
                            اسم الفئة
                            </Typography>
                        </Grid>
    
                        <Grid item  className={classes.item} >
                            <TextField
                                size='small'
                                type="text"
                                variant='outlined'
                                onChange={(e) => setCatName(e.target.value)}
                                value={catName}
                                fullWidth
                                placeholder="اسم الفئة"
                                sx={{
                                    mb: 4,
                                    '& .MuiInputBase-root': {
                                        fontSize: '0.7rem', // Smaller font size
                                    },
                                    '& .MuiInputLabel-root': {
                                        fontSize: '0.7rem', // Smaller label font size
                                    },
                                    '& .MuiOutlinedInput-root': {
                                        '& fieldset': {
                                            borderRadius: '7px', // Adjust border radius
                                        },
                                    },
                                }}                            />
                        </Grid>
                    
                        <Grid item style={{width: '100%',display: 'flex', justifyContent: 'flex-end' }}>
                            <Button
                                style={{ backgroundColor: '#FF6150', borderRadius: '7px' }}
                                size='small'
                                variant="contained"
                                onClick={e => handleAddCategory(catName, id, index)} >
                                {buttonName}
                            </Button>
                        </Grid>

                    </Grid>
                </Box>
            </Drawer >
        </>

    );
};

export default AddEditCategory;
