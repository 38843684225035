import React, { useEffect, useState } from 'react';
import {
    Card,
    CardContent,
    Grid,
    Paper,
    Link,
    TextField,
    Typography,
    Button,
} from '@mui/material';
import { connect } from 'react-redux';
import { makeStyles } from "tss-react/mui";
import { useHistory } from 'react-router-dom';


import addNewsIcon from "../../img/icons/add article V1.png";
import addStocksIcon from "../../img/icons/add stocks V1.png";
import addMediaIcon from "../../img/icons/add media V1.png";
import addWeatherIcon from "../../img/icons/add weathers V1.png";
import addCurrencyIcon from "../../img/icons/add currency V1.png";
import addAIIcon from "../../img/icons/mujaz AI V1.png";
import addSoonIcon from "../../img/icons/soon V1.png";
import editIcon from "../../img/icons/old/1-04.png"
import Drawer from '@mui/material/Drawer';




const News = (props) => {

    const [isOpenContent, setIsOpenContent] = useState(true)
    const [openDrawer, setOpenDrawer] = useState(false)

    const history = useHistory();

    const useStyles = makeStyles()(
        () => ({
            main: {
                width: '100%',
                '@media only screen and (min-width: 1200px)': {
                    width: '100%',
                },
            },
            container: {
                boxSizing: 'border-box',
                display: 'flex',
                flexWrap: 'nowrap',
                width: '100%',
                flexDirection: 'row',
                background: '#fefefe',
                justifyContent: 'space-between',
                alignItems: 'flex-start',
            },
            card: {
                cursor: 'pointer',
                padding: '20px',
                transition: 'background-color 0.3s ease',
                '&:hover': {
                    backgroundColor: '#FFCB00',
                },
            },
            icon: {
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            },
            textContainer: {
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
            },
        })
    );
    const { classes } = useStyles();

    const handleRedirectClick = (route) => {
        switch (route) {
            case 'newswithimage':
                history.push('/newswithimage');
                break;
            case 'newsstocks':
                history.push('/newsstocks');
                break;
            case 'newspdf':
                history.push('/newspdf');
                break;
            case 'newsweather':
                history.push('/newsweather');
                break;
            case 'newscurrency':
                history.push('/newscurrency');
                break;
            case 'chatgpt':
                history.push('/chatgpt');
                break;
            default:
                break;
        }
    };

    const toggleContent = (index) => {
        setIsOpenContent((isOpenContent) => !isOpenContent)
        // handleGridClick(index)
    }

    const toggleDrawer = (newOpen) => () => {
        setOpenDrawer(newOpen);
    };

    return (
        <>
            <Grid container spacing={2} mt={1} columns={{ xs: 1, sm: 1, md: 12 }} justifyContent={'center'}>
                <Grid item xs={1} sm={1} md={3} >
                    <Paper elevation={0} className={classes.card} onClick={() => handleRedirectClick('newswithimage')}>
                        <Grid container spacing={1} columns={{ md: 3 }} alignItems="center">
                            <Grid item md={0.5} className={classes.icon}>
                                <img src={addNewsIcon} width={30} />
                            </Grid>
                            <Grid item md={2.5} >
                                <Grid container columns={{ md: 2 }} alignItems="center">
                                    <Grid item md={2}>
                                        <Typography variant="body2" sx={{ fontWeight: 'bold' }}>إضافة مقال</Typography>
                                    </Grid>
                                    <Grid item md={2}>
                                        <Typography variant="body2" sx={{ fontSize: '10px', color: "EFF0F7" }}>انشاء و تحرير الاخبار والمقالات</Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
                <Grid item xs={1} sm={1} md={3} >
                    <Paper elevation={0} className={classes.card} onClick={() => handleRedirectClick('newsstocks')}>
                        <Grid container spacing={1} columns={{ md: 3 }} alignItems="center">
                            <Grid item md={0.5} className={classes.icon}>
                                <img src={addStocksIcon} width={30} />
                            </Grid>
                            <Grid item md={2.5} >
                                <Grid container columns={{ md: 2 }} alignItems="center">
                                    <Grid item md={2}>
                                        <Typography variant="body2" sx={{ fontWeight: 'bold' }}>سوق الاسهم</Typography>
                                    </Grid>
                                    <Grid item md={2}>
                                        <Typography variant="body2" sx={{ fontSize: '10px', color: "EFF0F7" }}>اضافه مؤشرات ومعطيات سوق الاسهم</Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
                <Grid item xs={1} sm={1} md={3} >
                    <Paper elevation={0} className={classes.card} onClick={() => handleRedirectClick('newspdf')}>
                        <Grid container spacing={1} columns={{ md: 3 }} alignItems="center">
                            <Grid item md={0.5} className={classes.icon}>
                                <img src={addMediaIcon} width={30} />
                            </Grid>
                            <Grid item md={2.5} >
                                <Grid container columns={{ md: 2 }} alignItems="center">
                                    <Grid item md={2}>
                                        <Typography variant="body2" sx={{ fontWeight: 'bold' }}>المرئيات</Typography>
                                    </Grid>
                                    <Grid item md={2}>
                                        <Typography variant="body2" sx={{ fontSize: '10px', color: "EFF0F7" }}>اضافه مؤشرات ومعطيات سوق الاسهم</Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>

            </Grid>
            <Grid container spacing={2} mt={1} columns={{ xs: 1, sm: 1, md: 12 }} justifyContent={'center'}>
                <Grid item xs={1} sm={1} md={3}>
                    <Paper elevation={0} className={classes.card} onClick={() => handleRedirectClick('newsweather')}>
                        <Grid container spacing={1} columns={{ md: 3 }} alignItems="center">
                            <Grid item md={0.5} className={classes.icon}>
                                <img src={addWeatherIcon} width={30} />
                            </Grid>
                            <Grid item md={2.5} >
                                <Grid container columns={{ md: 2 }} alignItems="center">
                                    <Grid item md={2}>
                                        <Typography variant="body2" sx={{ fontWeight: 'bold' }}>الطقس</Typography>
                                    </Grid>
                                    <Grid item md={2}>
                                        <Typography variant="body2" sx={{ fontSize: '10px', color: "EFF0F7" }}>اضافه الظروف الجويه في مدنك المفضله</Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
                <Grid item xs={1} sm={1} md={3}>
                    <Paper elevation={0} className={classes.card} onClick={() => handleRedirectClick('newscurrency')}>
                        <Grid container spacing={1} columns={{ md: 3 }} alignItems="center">
                            <Grid item md={0.5} className={classes.icon}>
                                <img src={addCurrencyIcon} width={30} />
                            </Grid>
                            <Grid item md={2.5} >
                                <Grid container alignItems="center">
                                    <Grid item >
                                        <Typography variant="body2" sx={{ fontWeight: 'bold' }}>العملات</Typography>
                                    </Grid>
                                    <Grid item >
                                        <Typography variant="body2" sx={{ fontSize: '10px', color: "EFF0F7" }}>قم بمشاركه اخر التغيرات الاساسيه في سوق العملات</Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
                <Grid item xs={1} sm={1} md={3}>
                    <Paper elevation={0} className={classes.card} style={{backgroundColor: '#FFCB00',}}onClick={() => handleRedirectClick('chatgpt')}>
                        <Grid container spacing={1} columns={{ md: 3 }} alignItems="center">
                            <Grid item md={0.5} className={classes.icon}>
                                <img src={addAIIcon} width={30} />
                            </Grid>
                            <Grid item md={2.5} >
                                <Grid container columns={{ md: 2 }} alignItems="center">
                                    <Grid item md={2}>
                                        <Typography variant="body2" sx={{ fontWeight: 'bold' }}>مساعد موجز الرقمي</Typography>
                                    </Grid>
                                    <Grid item md={2}>
                                        <Typography variant="body2" sx={{ fontSize: '10px', color: "EFF0F7" }}>اسأل مساعد موجز الرقمي لمساعدتك في إنجاز مهامك</Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
            </Grid>
            <Grid container spacing={2} mt={1} columns={{ xs: 1, sm: 1, md: 12 }} justifyContent={'center'}>
                <Grid item xs={1} sm={1} md={3} >
                    <Paper elevation={0} className={classes.card}>
                        <Grid container spacing={1} columns={{ md: 3 }} alignItems="center">
                            <Grid item md={0.5} className={classes.icon}>
                                <img src={addSoonIcon} width={30} />
                            </Grid>
                            <Grid item md={2.5} >
                                <Grid container columns={{ md: 2 }} alignItems="center">
                                    <Grid item md={2}>
                                        <Typography variant="body2" sx={{ fontWeight: 'bold' }}> قريبا</Typography>
                                    </Grid>
                                    <Grid item md={2}>
                                        <Typography variant="body2" sx={{ fontSize: '10px', color: "EFF0F7" }}></Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
                <Grid item xs={1} sm={1} md={3} >
                    {/* empty grid */}
                </Grid>
                <Grid item xs={1} sm={1} md={3} >
                    {/* empty grid */}
                </Grid>
            </Grid>
            <div>
                {/* <Button
                    variant="contained"
                    color="primary"
                    style={{
                        position: 'fixed',
                        top: '50%',
                        transform: 'translateY(-50%)',
                        left: 0,
                        background: '#FF6150',
                    }}
                    startIcon={<img src={editIcon} alt="Edit Icon" style={{ width: '40px', height: '40px' }} />}
                >
                </Button > */}
            </div>
            {/* <Drawer open={openDrawer} anchor={"right"} onClose={toggleDrawer(false)} >
                <Grid conatainer spacing={2} className={classes.gridContainer} style={{ flexDirection: 'column', }} >
                </Grid>
            </Drawer> */}

        </>
    );
}

const mapStateToProps = (state) => {
    return state
}

export default connect(mapStateToProps)(News);
