import { Divider, Grid, Stack, TextField, Paper, Button, Typography, Autocomplete } from '@mui/material';
import React, { useState, useEffect, useRef } from 'react';
import RichTextEditor from '../../atoms/RichTextEditor';
import ImageUploader from '../ImageUploader';
import { makeStyles } from "tss-react/mui";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { doc, addDoc, getDoc, collection, getFirestore, getDocs, setDoc, query, where } from 'firebase/firestore';
import SearchIcon from '@mui/icons-material/Search';
import SaveIcon from '@mui/icons-material/Save';
import Preview from '../../components/Preview';
import { generateKey } from '../../utils';

const ConfirmNewsWithImage = ({ props, defaultValue, handleCancelConfirmCard, handleEditConfirmNewsWithImageCard }) => {
    const [section, setSection] = useState(
        defaultValue && defaultValue.section ? defaultValue.section : ''
    );
    console.log(defaultValue);
    const [title, setTitle] = useState(
        defaultValue && defaultValue.title ? defaultValue.title : ''
    );
    const [body, setBody] = useState(defaultValue && defaultValue.body ? defaultValue.body : '');
    const [image, setImage] = useState(
        defaultValue && defaultValue.image ? defaultValue.image : ''
    );
    const [credits, setCredits] = useState(
        defaultValue && defaultValue.credits ? defaultValue.credits : ''
    );
    const [alt, setAlt] = useState(defaultValue && defaultValue.alt ? defaultValue.alt : '');
    const [catOptions, setCatOptions] = useState([]);
    const [category, setCategory] = useState(defaultValue.category);
    const [valueDate, setValueDate] = useState(_formatDate(defaultValue.date, '/'));
    const [intro, setIntro] = useState('');
    const [items, setItems] = useState([]);
    const db = getFirestore();
    const previewCompRef = useRef();
    const { company, data } = props;
    const useStyles = makeStyles()(
        () => ({
            main: {
                padding: '20px'

            },
            paper: {
                padding: '20px',
            },
            marginNormal: {
                marginTop: '25px',
                marginBottom: '25px'
            },
            cancelBtn: {
                boxShadow: '0px 3px 6px #00000026',
            },
            confirmBtn: {
                marginRight: '15px',
                boxShadow: '0px 3px 6px #00000026',
            },
            content: {
                background: '#fff',
                borderRadius: '25px',
                padding: '25px',
            }
        })
    );

    const { classes } = useStyles();

    const dateFormat = (inputDate, format) => {
        //parse the input date
        const date = new Date(inputDate);
        console.log(date);
        //extract the parts of the date
        const day = date.getDate();
        const month = date.getMonth() + 1;
        const year = date.getFullYear();

        //replace the month
        format = format.replace("MM", month.toString().padStart(2, "0"));

        //replace the year
        if (format.indexOf("yyyy") > -1) {
            format = format.replace("yyyy", year.toString());
        } else if (format.indexOf("yy") > -1) {
            format = format.replace("yy", year.toString().substr(2, 2));
        }

        //replace the day
        format = format.replace("dd", day.toString().padStart(2, "0"));

        return format;
    }

    const getCategories = async () => {
        const categoriesRef = collection(db, "Categories");
        const q = query(categoriesRef, where("company", "==", "mujaz"));
        let data = await getDocs(q);
        data.forEach((doc) => {
            console.log(doc.data());
            setCatOptions(catOptions => [...catOptions, doc.data().name]);
        });

    }
    function _formatDate(dateIn, symbol) {
        let date = dateIn.split('-')[1] + '-' + dateIn.split('-')[0] + '-' + dateIn.split('-')[2];
        date = new Date(date);
        let yyyy = date.getFullYear();
        let mm = date.getMonth() + 1;
        if (symbol != '-') {
            mm = mm.toString().padStart(2, '0');
        }
        let dd = date.getDate();
        return String(mm + symbol + dd + symbol + yyyy);
    }
    const handleDateChange = (newValue) => {
        setValueDate(newValue);
    }
    const PreviewFn = () => {
        setItems([{
            id: generateKey(),
            category: category,
            section: section,
            title: title,
            image: image,
            alt: alt,
            credits: credits,
            body: body,
            date: `${parseInt(new Date().getDate())}-${new Date().getMonth() + 1
                }-${new Date().getFullYear()}`
        }])

        previewCompRef.current.showPreview();
    }

    useEffect(() => {
        getCategories();
    }, []);
    useEffect(() => {
        if (items.length > 0) {
            console.log(items);
            previewCompRef.current.showPreview();
        }
    }, [items]);
    return (
        <>
            <Grid container spacing={4} mt={1} style={{ marginRight: 0, width: '100%' }}>
                <Grid item xs={6} className={classes.content}>
                    <Stack spacing={3} direction="column">
                        <Typography variant="h5">خبر</Typography>
                        <Stack direction="column" spacing={1}>
                            <Autocomplete
                                options={catOptions}
                                value={category}
                                disableClearable
                                onChange={(e, cat) => setCategory(cat)}
                                renderInput={(params) => <TextField {...params} label="الفئة" />}
                            />
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <DesktopDatePicker
                                    label="التاريخ"
                                    inputFormat="MM/dd/yyyy"
                                    value={valueDate}
                                    onChange={handleDateChange}
                                    renderInput={(params) => <TextField {...params} />}
                                />
                            </LocalizationProvider>
                            {/* <TextField
								fullWidth
								value={section}
								onChange={({ target: { value } }) => setSection(value)}
								variant="outlined"
								label="الفقرة"
								placeholder="الفقرة"
							/> */}
                            <TextField
                                fullWidth
                                value={title}
                                onChange={({ target: { value } }) => setTitle(value)}
                                variant="outlined"
                                label="العنوان"
                                placeholder="العنوان"
                            />
                        </Stack>
                        <ImageUploader image={image} onChange={setImage} />
                        <Stack direction="row" spacing={1}>
                            <TextField
                                fullWidth
                                value={credits}
                                onChange={({ target: { value } }) => setCredits(value)}
                                variant="outlined"
                                label="المصدر"
                                placeholder="المصدر"
                            />
                            <TextField
                                fullWidth
                                value={alt}
                                onChange={({ target: { value } }) => setAlt(value)}
                                variant="outlined"
                                label="وصف الصورة"
                                placeholder="وصف الصورة"
                            />
                        </Stack>
                        <RichTextEditor defaultValue={body} onChange={setBody} />
                    </Stack>
                </Grid>
                <Grid item xs={6} style={{ paddingTop: 0 }}>
                    <Preview data={items} compData={data} intro={intro} ref={previewCompRef} />
                </Grid>
                <Grid container mt={4}>
                    <Grid item xs={6} style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <Button
                            className={classes.cancelBtn}
                            variant="contained"
                            onClick={handleCancelConfirmCard}
                        >
                            رجوع
                        </Button>
                        <Button
                            className={classes.confirmBtn}
                            variant="contained"
                            onClick={e => handleEditConfirmNewsWithImageCard(alt, credits, image, section, title, body, category, valueDate, defaultValue)}
                        >
                            تأكيد
                        </Button>
                        <Button variant="contained" className={classes.previewBtn} endIcon={<SearchIcon />} onClick={PreviewFn}>معاينة</Button>					</Grid>
                </Grid>
            </Grid>

        </>
    );
};

export default ConfirmNewsWithImage;
