import { Paper, Grid, Stack, TextField, Button, Autocomplete, Typography, Snackbar, Avatar } from '@mui/material';
import React, { useState, useEffect, useRef } from 'react';
import RichTextEditor from '../../atoms/RichTextEditor';
import ImageUploader from '../../components/ImageUploader';
import Footer from '../../components/Footer';
import SideBarMenu from '../../components/SideBarMenu';
import { makeStyles } from "tss-react/mui";
import { InputAdornment, IconButton } from '@material-ui/core';
import { doc, addDoc, getDoc, collection, getFirestore, getDocs, setDoc, query, where } from 'firebase/firestore';
import HTMLViewer from '../../components/HTMLViewer';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { generateKey } from '../../utils';
import SearchIcon from '@mui/icons-material/Search';
import SaveIcon from '@mui/icons-material/Save';
import Preview from '../../components/Preview';
import { connect } from 'react-redux';
import OpenAI from 'openai-api';
import { Configuration, OpenAIApi } from 'openai';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import SendIcon from '@mui/icons-material/Send';
import CircularProgress from '@mui/material/CircularProgress';
import { TypeAnimation } from 'react-type-animation';

import { CopyToClipboard } from "react-copy-to-clipboard";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import ContentCopyRoundedIcon from '@mui/icons-material/ContentCopyRounded';
import addAIIcon from "../../img/icons/mujaz AI V1.png";

const ChatGPT = (props) => {
    const [category, setCategory] = useState('');
    const [section, setSection] = useState('');
    const [title, setTitle] = useState('');
    const [copyText, setCopyText] = useState('');
    const [body, setBody] = useState(copyText);
    const [intro, setIntro] = useState('');
    const [items, setItems] = useState([]);
    const [image, setImage] = useState('');
    const [credits, setCredits] = useState('');
    const [alt, setAlt] = useState('');
    const [imageURL, setImageURL] = useState('');
    const [catOptions, setCatOptions] = useState([]);
    const [valueDate, setValueDate] = useState(new Date());
    const [text, setText] = useState('');
    const [loadingResponse, setLoadingResponse] = useState(false);
    const [isCopied, setIsCopied] = useState(false);

    const [snackBar, setSnackBar] = useState({
        isOpen: false,
        message: '',
    });
    const db = getFirestore();
    const previewCompRef = useRef();
    const { company, data, loggedInUser } = props;
    const useStyles = makeStyles()(
        () => ({
            main: {
                width: '100%',
                '@media only screen and (min-width: 1200px)': {
                    width: '100%',
                },
            },
            container: {
                boxSizing: 'border-box',
                display: 'flex',
                flexWrap: 'nowrap',
                width: '100%',
                flexDirection: 'row',
                background: '#fefefe',
                justifyContent: 'space-between',
                alignItems: 'flex-start',
            },
            welomeText: {
                color: '#ff6633',
                fontWeight: 700,
            },
            paper: {
                display: 'flex',
                flexWrap: 'nowrap',
                alignItems: 'center',
                flexDirection: 'column',
                marginTop: 210,
            },
            content: {
                background: '#ffeec7',
                borderRadius: '25px',
                padding: '25px',
            },
            TextChat: {
                '& .MuiInputBase-root': {
                    fontSize: '0.7rem',
                    height: '150px',
                },
                '& .MuiInputLabel-root': {
                    fontSize: '0.7rem',
                },
                '& .MuiOutlinedInput-root': {
                    borderRadius: '10px'
                },
                "& fieldset": {
                    border: 'none',
                },
            },
            icon: {
                display: 'flex',
                justifyContent: 'flex-start',
            },
            fieldStyle: {
                '& .MuiInputBase-root': {
                    fontSize: '0.7rem',
                },
                '& .MuiInputLabel-root': {
                    fontSize: '0.7rem',
                },
                '& .MuiOutlinedInput-root': {
                    borderRadius: '10px'
                },
                "& fieldset": {
                    border: 'none',
                },
            },

        })
    );
    const [messages, setMessages] = useState([
        {
            role: "assistant",
            content: "مرحبا بكّ أنا المساعد الخاص  من موجز، أنا هنا من اجل مساعدتك في انشاء المحتوى الخاص بك"
        }
    ]);
    const OPENAI_API_KEY = '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';
    const configuration = new Configuration({
        apiKey: OPENAI_API_KEY,
    });
    const openai = new OpenAIApi(configuration);
    // const openai = new OpenAI(OPENAI_API_KEY);


    const { classes } = useStyles();

    const getDateId = (_company) => {
        return `${_company}_${parseInt(new Date().getDate()) + (new Date().getHours() < 5 ? 0 : 1)}-${(new Date().getMonth() + 1).toString().padStart(2, '0')
            }-${new Date().getFullYear()}`;
    };
    const PreviewFn = () => {
        setItems([{
            id: generateKey(),
            category: category,
            section: section,
            title: title,
            image: image,
            imageURL: imageURL,
            alt: alt,
            credits: credits,
            body: body,
            date: `${parseInt(new Date().getDate())}-${new Date().getMonth() + 1
                }-${new Date().getFullYear()}`
        }])

        previewCompRef.current.showPreview();
    }
    const convertDate = (_company, date) => {
        return `${_company}_${parseInt(date.getDate())}-${(new Date().getMonth() + 1).toString().padStart(2, '0')}-${date.getFullYear()}`;
    }

    const saveNewsWithImage = async () => {
        const docTitle = convertDate(company, valueDate);
        let data = await getDoc(doc(db, 'NewsLibrary', docTitle));
        if (data.data()) {
            await setDoc(doc(db, 'NewsLibrary', docTitle), {
                News: data.data().News.concat({
                    id: generateKey(),
                    category: category,
                    section: section,
                    title: title,
                    image: image,
                    imageURL: imageURL,
                    alt: alt,
                    credits: credits,
                    body: body,
                    date: `${parseInt(new Date().getDate())}-${(new Date().getMonth() + 1).toString().padStart(2, '0')
                        }-${new Date().getFullYear()}`
                }),
                Stocks: data.data().Stocks,
                Weather: data.data().Weather,
                Image: data.data().Image,
                Currency: data.data().Currency,
                data: data.data().data
            }).then((data) => {
                // console.log('done update');
            });
        } else {
            await setDoc(doc(db, 'NewsLibrary', docTitle), {
                News:
                    [
                        {
                            id: generateKey(),
                            category: category,
                            section: section,
                            title: title,
                            image: image,
                            imageURL: imageURL,
                            alt: alt,
                            credits: credits,
                            body: body,
                            date: `${parseInt(new Date().getDate())}-${(new Date().getMonth() + 1).toString().padStart(2, '0')
                                }-${new Date().getFullYear()}`
                        }
                    ]
                ,
                Stocks: '',
                Weather: '',
                Image: [],
                Currency: '',
                data: {
                    date: `${parseInt(new Date().getDate())}-${(new Date().getMonth() + 1).toString().padStart(2, '0')
                        }-${new Date().getFullYear()}`,
                    company: company
                }
            }).then((data) => {
                // console.log('done');
            });
        }
        setSnackBar({
            isOpen: true,
            message: 'تم الحفظ في مكتبة الفئات',
        });
    }

    const getCategories = async () => {
        const categoriesRef = collection(db, "Categories");
        const q = query(categoriesRef, where("company", "==", company));
        let data = await getDocs(q);
        data.forEach((doc) => {
            setCatOptions(catOptions => [...catOptions, doc.data().name]);
        });
    }
    const sendChatGPTAPIRequest = async (content) => {
        const chatCompletion = await openai.createChatCompletion({
            model: "gpt-3.5-turbo",
            messages: [{ role: "user", content: content }],
        });
        setMessages((prevMessages) => [
            ...prevMessages,
            {
                role: chatCompletion.data.choices[0].message.role,
                content: chatCompletion.data.choices[0].message.content,
            },
        ]);
        setLoadingResponse(false);
    };
    const sendTextToChatGPT = () => {
        setLoadingResponse(true);
        setMessages((prevMessages) => [
            ...prevMessages,
            {
                role: loggedInUser.name,
                content: text,
            },
        ]);
        sendChatGPTAPIRequest(text);
        setText('');
    }
    const sendTextToRichText = (text) => {
        setBody(text);
    }
    const handleDateChange = (newValue) => {
        setValueDate(newValue);
    }
    useEffect(() => {
        // sendChatGPTAPIRequest();
        getCategories();
        // console.log(data);
    }, []);
    useEffect(() => {
        if (items.length > 0) {
            console.log(items);
            previewCompRef.current.showPreview();
        }
    }, [items]);

    const handleImageChange = (newImageURL) => {
        setImage(newImageURL);
        setImageURL(newImageURL);
    };
    return (
        <>
            <Grid container spacing={4} columns={{ xs: 1, sm: 1, md: 12 }} style={{ padding: '0px', marginRight: 0, width: '100%', }} className={classes.content} >
                <Grid item xs={1} sm={1} md={12} style={{ padding: '20px 15px 0px 0px' }}>
                    <Grid container columns={{ xs: 1, sm: 1, md: 12 }} mb={2} alignItems="center">
                        <Grid item md={0.5} className={classes.icon}>
                            <img src={addAIIcon} width={30} />
                        </Grid>
                        <Grid item xs={1} sm={1} md={10}>
                            <Typography variant="body2" style={{ fontWeight: 600 }}>مساعد موجز الرقمي المدعوم بالذكاء الاصطناعي</Typography>
                        </Grid>
                        {/* <Grid item xs={1} sm={1} md={2} style={{
                            display: 'flex',
                            justifyContent: 'flex-end'
                        }}>
                            <InfoOutlinedIcon style={{ fontSize: '1.4rem' }} />
                        </Grid> */}
                    </Grid>
                    {messages?.map((message, index) => {
                        return (
                            <Grid container columns={{ xs: 1, sm: 1, md: 12 }} index={index} key={index} alignItems='center'>
                                <Grid item xs={1} sm={1} md={10.5} style={{
                                    // background: message.role == 'assistant' ? '#f27021' : '#eee',
                                    background: 'white',
                                    borderTopLeftRadius: '10px',
                                    borderBottomLeftRadius: '10px',
                                    borderBottomRightRadius: '10px',
                                    borderTopRightRadius: '10px',
                                    padding: '2px 10px',
                                    color: message.role == 'assistant' ? '#fff' : '#000',
                                    fontSize: '17px',

                                }}>
                                    <Grid container columns={{ xs: 1, sm: 1, md: 12 }} >
                                        {/* <Grid item xs={1} sm={1} md={12} style={{ fontWeight: 600 }}>
                                            {message.role == 'assistant' ? 'مساعد موجز الرقمي' : loggedInUser?.name}
                                        </Grid> */}
                                        <Grid item xs={1} sm={1} md={12} mt={1} >
                                            {message.role == 'assistant' ? <TypeAnimation
                                                sequence={[
                                                    message?.content, // Types 'One'
                                                    1000, // Waits 1s
                                                ]}
                                                wrapper="h2"
                                                cursor={false}
                                                repeat={1}
                                                style={{
                                                    color: 'black',
                                                    fontSize: '1em',
                                                    fontWeight: '200',
                                                    textAlign: 'right'
                                                }}
                                            /> :
                                                message?.content}
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={1} sm={1} md={1.5}>
                                    <Grid container spacing={1} columns={1}>
                                        {/* <Grid item>
                                            <Avatar>{message.role.charAt(0)}</Avatar>
                                        </Grid> */}
                                        {message.role == 'assistant' ?
                                            <Grid item>
                                                <CopyToClipboard text={message.content} onCopy={sendTextToRichText}>
                                                    <IconButton aria-label="copy" size='medium' style={{ marginRight: '3px' }}>
                                                        {isCopied ? <ContentCopyRoundedIcon color='primary' fontSize='inherit' /> : <ContentCopyIcon fontSize="inherit" />}
                                                    </IconButton>
                                                </CopyToClipboard>
                                                {/* <InputAdornment position="end">
                                                    <IconButton size='small' style={{ marginRight: '3px' }} onClick={() => sendTextToRichText(`${message.content}`)}>
                                                        <ArrowCircleDownIcon color='primary' />
                                                    </IconButton>
                                                </InputAdornment> */}
                                            </Grid>
                                            : null}
                                    </Grid>
                                </Grid>
                            </Grid>
                        );
                    })
                    }
                    <Grid container columns={{ xs: 1, sm: 1, md: 12 }} mt={2} mb={2}>
                        <Grid item xs={1} sm={1} md={10.5}>
                            <TextField
                                classes={{ root: classes.TextChat }}
                                fullWidth
                                size="small"
                                type="email"
                                id="outlined-search"
                                placeholder="اكتب السؤال هنا"
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton onClick={sendTextToChatGPT} style={{ alignSelf: 'flex-end' }}>
                                                <SendIcon style={{ color: '#000' }} />
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                                onChange={e => setText(e.target.value)}
                            />
                        </Grid>
                        <Grid item xs={1} sm={1} md={1.5}>
                            {loadingResponse && <CircularProgress size={35} color='primary' />}
                        </Grid>
                    </Grid>
                </Grid >
            </Grid >

            <Grid container spacing={3} columns={{ xs: 1, sm: 1, md: 12 }} mt={2} style={{ marginRight: 0, width: '100%' }}>
                <Grid item xs={6} md={6} className={classes.content} style={{ background: '#EFF0F7' }}>
                    <Stack spacing={3} direction="column">
                        <Stack direction="column" spacing={3}>
                            <Autocomplete
                                className={classes.fieldStyle}
                                options={catOptions}
                                disableClearable
                                onChange={(e, cat) => setCategory(cat)}
                                renderInput={(params) => <TextField {...params} label="الفئة" />}
                            />
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <DesktopDatePicker
                                    placeholder="التاريخ"
                                    inputFormat="MM/dd/yyyy"
                                    value={valueDate}
                                    onChange={handleDateChange}
                                    renderInput={(params) =>
                                        <TextField
                                            {...params}
                                            sx={{
                                                '& .MuiSvgIcon-root': {
                                                    color: '#FF6150', // Change the color of the icon
                                                },
                                                '& .MuiInputBase-root': { // Replace with your actual class name
                                                    height: '50px',
                                                    borderRadius: '15px',
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                },
                                                '& .MuiInputBase-input': {
                                                    textAlign: 'center',
                                                    alignSelf: 'baseline',
                                                },
                                                "& fieldset": {
                                                    border: 'none',
                                                },
                                            }} />}
                                />
                            </LocalizationProvider>
                            <TextField
                                fullWidth
                                value={title}
                                onChange={({ target: { value } }) => setTitle(value)}
                                variant="outlined"
                                //label="العنوان"
                                placeholder="العنوان الرئيسي"
                                className={classes.fieldStyle}
                            />
                        </Stack>
                        <Grid container alignItems="center" columns={{ sm: 1, md: 12 }}>
                            <Grid item md={2}>
                                <ImageUploader textImage={'إضافة صورة'} image={image} onChange={handleImageChange}/>
                            </Grid>
                            <Grid item md={10}>
                                <TextField
                                    fullWidth
                                    value={imageURL}
                                    onChange={({ target: { value } }) => setImageURL(value)}
                                    variant="outlined"
                                    label="رابط الصورة"
                                    placeholder="رابط الصورة"
                                    className={classes.fieldStyle}
                                    inputProps={
										{ readOnly: true, }
									}
                                />
                            </Grid>
                        </Grid>
                        <Stack direction="row" spacing={1}>
                            <TextField
                                fullWidth
                                value={credits}
                                onChange={({ target: { value } }) => setCredits(value)}
                                variant="outlined"
                                // label="المصدر"
                                placeholder="المصدر"
                                className={classes.fieldStyle}
                            />
                            <TextField
                                fullWidth
                                value={alt}
                                onChange={({ target: { value } }) => setAlt(value)}
                                variant="outlined"
                                //label="الوصف"
                                placeholder="الوصف "
                                className={classes.fieldStyle}
                            />

                        </Stack>
                        <RichTextEditor defaultValue={body} onChange={setBody} />
                        <Grid container mt={4} columns={{ sm: 1, md: 12 }} justifyContent="space-between" alignItems="center">
                            <Grid item xs={6} md={4} >
                                <Button size='small' variant="outlined" style={{ color: '#FF6150', border: '1px solid', borderRadius: '15px', padding: '10px' }} endIcon={<SearchIcon />} onClick={PreviewFn}>معاينة</Button>
                            </Grid>
                            <Grid item xs={6} md={6} sx={{ display: 'grid' }}>
                                <Button size='small' variant="contained" style={{ backgroundColor: '#FF6150', borderRadius: '15px', padding: '10px', }} endIcon={<SaveIcon />} onClick={saveNewsWithImage}>حفظ في مكتبة الفئات</Button>
                            </Grid>
                        </Grid>
                    </Stack>
                </Grid>
                <Grid item xs={6} md={6} style={{ paddingTop: 0 }}>
                    <Preview data={items} compData={data} intro={intro} ref={previewCompRef} />
                </Grid>
            </Grid>
            <Snackbar
                open={snackBar.isOpen}
                autoHideDuration={6000}
                onClose={() => setSnackBar({ ...snackBar, isOpen: false })}
                message={snackBar.message}
            />
        </>
    );
};
const mapStateToProps = (state) => {
    console.log(state);
    return state
}

export default connect(mapStateToProps)(ChatGPT);
