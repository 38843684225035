import React, { useEffect, useState } from 'react';
import {
    Button,
    Stack,
    Grid,
    Paper,
    TextField,
    Typography,
    Divider,
    Snackbar,
    Checkbox,
    Autocomplete,
    IconButton,
    Card,
    CardContent,
    MenuItem,
    FormControl,
    InputLabel,
    Select,
    CircularProgress
} from '@mui/material';
import { format } from 'date-fns';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import ContentCopyRoundedIcon from '@mui/icons-material/ContentCopyRounded';
import ImageUploaderSetting from '../../components/ImageUploaderSetting';
import FormControlLabel from '@mui/material/FormControlLabel';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { Link } from 'react-router-dom';
import { makeStyles } from "tss-react/mui";
import { ChromePicker } from 'react-color';
import { doc, addDoc, deleteDoc, getDoc, documentId, getFirestore, getDocs, setDoc, collection, query, where } from 'firebase/firestore';
import { getAuth, onAuthStateChanged, updatePassword, reauthenticateWithCredential, EmailAuthProvider } from "firebase/auth";
import { connect } from 'react-redux';
import PackagesPlan from '../../components/PackagesPlans';
import { CopyToClipboard } from "react-copy-to-clipboard";
import { PrismLight as SyntaxHighlighter } from "react-syntax-highlighter";
import { okaidia, dark } from "react-syntax-highlighter/dist/esm/styles/prism";
import profileSettingsIcon from "../../img/icons/Profile settings V1.png"
import bulletinSettingsIcon from "../../img/icons/Bulletin settings V1.png"
import userManagementIcon from "../../img/icons/User management V1.png"
import packageIcon from "../../img/icons/Package and balance information V1.png"
import linkingIcon from "../../img/icons/Linking and embedding V1.png"
import Users from '../../pages/Dashboard/Users';



const Settings = (props) => {
    const [selectedGrid, setSelectedGrid] = useState(null);
    const [isOpenContent, setIsOpenContent] = useState(true)
    const { id, data, loggedInUser } = props;
    const [snackBar, setSnackBar] = useState({
        isOpen: false,
        message: '',
    });
    const [isCopiedOne, setIsCopiedOne] = useState(false);
    const [isCopiedTwo, setIsCopiedTwo] = useState(false);
    const [isCopiedThree, setIsCopiedThree] = useState(false);

    const codeSnippetJS = `<script src="https://firebasestorage.googleapis.com/v0/b/mujaz-platform.appspot.com/o/subscriptionJs%2Fsubscription-form.js?alt=media&token=afe5098e-d5bb-4389-a553-49946c339002"></script>`;
    const codeSnippetHTML = `<div class="subscribe-emails"></div>`;
    const codeSnippetFn = `<script>injectSubscriptionForm('subscribe-emails', '${data?.personalProfile?.companyData?.company}');</script>`;
    const [username, setUsername] = useState('');
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [company, setCompany] = useState('');
    const [website, setWebsite] = useState('');
    const [address, setAddress] = useState('');
    const [city, setCity] = useState('');
    const [country, setCountry] = useState('');
    const [sender, setSender] = useState('');
    const [senderEmail, setSenderEmail] = useState('');

    const [awsSES, setAwsSES] = useState('');
    const [awsCW, setAwsCW] = useState('');

    const [toPreview, setToPreview] = useState(false);
    const [primaryColor, setPrimaryColor] = useState('');
    const [secondaryColor, setSecondaryColor] = useState('');
    const [logo, setLogo] = useState('');
    const [footerLogo, setFooterLogo] = useState('');
    const [footerText, setFooterText] = useState('');
    const [footerSlogonText, setFooterSlogonText] = useState('');
    const [footerCopyrightText, setFooterCopyrightText] = useState('');
    const [footerUnsubscribeText, setFooterUnsubscribeText] = useState('');
    const [facebook, setFacebook] = useState('');
    const [instagram, setInstagram] = useState('');
    const [twitter, setTwitter] = useState('');
    const [youtube, setYoutube] = useState('');
    const [fontStyle, setFontStyle] = useState('');

    const [_package, setPackage] = useState(
        {
            type: 'تجريبية',
            active: 'نشط',
            sentEmails: 0,
            totalEmails: 0
        }
    );
    const plans = [
        {
            type: 'flexible',
            name: 'حزمة مرنة'
        },
        {
            type: 'fixed',
            name: 'حزمة ثابتة'
        }
    ]
    const PlanPackages = [
        {
            type: 'flexible',
            code: 'flexible',
            name: 'حزمة مرنة',
            fixedCost: 25,
            costPerEmails: 0.7,
            numberOfEmails: 1000,
            MaxTotalEmails: 50000,
        },
        {
            type: 'fixed',
            code: 'fixed1',
            name: 'حزمة ثابتة',
            fixedCost: 0,
            costPerEmails: 46,
            numberOfEmails: 50000,
            MaxTotalEmails: 1000000,
        },
        {
            type: 'fixed',
            code: 'fixed2',
            name: 'حزمة ثابتة',
            fixedCost: 0,
            costPerEmails: 63,
            numberOfEmails: 100000,
            MaxTotalEmails: 1000000,
        },
        {
            type: 'fixed',
            code: 'fixed3',
            name: 'حزمة ثابتة',
            fixedCost: 0,
            costPerEmails: 97,
            numberOfEmails: 200000,
            MaxTotalEmails: 1000000,
        },
        {
            type: 'fixed',
            code: 'fixed4',
            name: 'حزمة ثابتة',
            fixedCost: 0,
            costPerEmails: 144,
            numberOfEmails: 350000,
            MaxTotalEmails: 1000000,
        },
        {
            type: 'fixed',
            code: 'fixed5',
            name: 'حزمة ثابتة',
            fixedCost: 0,
            costPerEmails: 184,
            numberOfEmails: 500000,
            MaxTotalEmails: 1000000,
        },
        {
            type: 'fixed',
            code: 'fixed6',
            name: 'حزمة ثابتة',
            fixedCost: 0,
            costPerEmails: 324,
            numberOfEmails: 1000000,
            MaxTotalEmails: 1000000,
        },
    ]

    const [displayPrimaryColorPicker, setDisplayPrimaryColorPicker] = useState(false);
    const [displaySecondaryColorPicker, setDisplaySecondaryColorPicker] = useState(false);

    const [openUpgradePlan, setOpenUpgradePlan] = useState(false);

    const [selectedPlanByUserToUpgrade, setSelectedPlanByUserToUpgrade] = useState();
    const [selectedPlan, setSelectedPlan] = useState();
    const [selectedPlanCode, setSelectedPlanCode] = useState('');
    const handleSelectPlan = (plan) => {
        console.log(plan.code)
        setSelectedPlanCode(plan.code);
        setSelectedPlanByUserToUpgrade(plan);
    };

    const filteredPlanPackages = PlanPackages.filter((item) =>
        selectedPlan ? item.type === selectedPlan.type : false
    );
    const accountData =
    {
        personalProfile: {
            personalData:
            {
                username: username,
                name: name,
                email: email
            },
            companyData:
            {
                company: company,
                website: website,
                address: address,
                city: city,
                country: country,
            }
        },
        settings:
        {
            senderSettings:
            {
                name: sender,
                email: senderEmail,
                toPreview: toPreview,
                defaultFrom: props?.data?.settings?.senderSettings?.defaultFrom || sender + ' <' + senderEmail + '>',
                defaultReplyTo: props?.data?.settings?.senderSettings?.defaultReplyTo || senderEmail,
                aws: {
                    ses: awsSES,
                    cw: awsCW
                }
            },
            sponsorShip: {
                enabled: props?.data?.settings?.sponsorShip?.enabled,
                type: props?.data?.settings?.sponsorShip?.type
            },
            view:
            {
                logo: logo,
                primaryColor: primaryColor,
                secondaryColor: secondaryColor,
            },
            footer:
            {
                footerLogo: footerLogo,
                text: footerText,
                slogon: footerSlogonText,
                copyrightText: footerCopyrightText,
                unsubscribeText: footerUnsubscribeText,
                socialMedia:
                {
                    facebook: facebook,
                    instagram: instagram,
                    twitter: twitter,
                    youtube: youtube
                }
            },
            package: {
                type: props?.data?.settings?.package?.type,
                active: props?.data?.settings?.package?.active,
                sentEmails: props?.data?.settings?.package?.sentEmails,
                totalEmails: props?.data?.settings?.package?.totalEmails
            }
        }

    }
    const updatedUserData = {
        company: loggedInUser?.company,
        email: loggedInUser?.email,
        logo: loggedInUser?.logo,
        name: loggedInUser?.name,
        responsibility: loggedInUser?.responsibility,
        uid: loggedInUser?.uid,
        _2fa: {
            enabled: loggedInUser?._2fa?.enabled,
            secret: loggedInUser?._2fa?.secret
        }
    };
    const [tabValue, setTabValue] = React.useState('الملف الشخصي');
    const db = getFirestore();
    const auth = getAuth();
    const user = auth.currentUser;



    const handleCopyOne = () => {
        setIsCopiedOne(true);
        setTimeout(() => setIsCopiedOne(false), 2000);
    };
    const handleCopyTwo = () => {
        setIsCopiedTwo(true);
        setTimeout(() => setIsCopiedTwo(false), 2000);
    };
    const handleCopyThree = () => {
        setIsCopiedThree(true);
        setTimeout(() => setIsCopiedThree(false), 2000);
    };

    const useStyles = makeStyles()(
        () => ({
            main: {
                width: '100%',
                '@media only screen and (min-width: 1200px)': {
                    width: '100%',
                },
            },
            container: {
                boxSizing: 'border-box',
                display: 'flex',
                flexWrap: 'nowrap',
                width: '100%',
                flexDirection: 'row',
                background: '#fefefe',
                justifyContent: 'space-between',
                alignItems: 'flex-start',
            },
            content: {
                paddingRight: '20px',
            },
            welomeText: {
                color: '#ff6633',
                fontWeight: 700,
            },
            paper: {
                display: 'flex',
                flexWrap: 'nowrap',
                alignItems: 'center',
                flexDirection: 'column',
                marginTop: 210,
            },
            paperList: {
                display: 'flex',
                justifyContent: 'space-between',
                flexDirection: 'row',
                alignItems: 'center',
                padding: '20px 30px',
                marginBottom: 15,
                fontWeight: 400
            },
            TabList: {
                display: 'flex',
                justifyContent: 'space-around',
            },
            editDeleteBtns: {
                display: 'flex',
            },
            btns: {
                color: '#ff6633'
            },
            textContainer: {
                width: '100%',
                margin: '20px 0',
            },
            textPaper: {
                width: '100%',
                padding: '40px 30px 0px 30px',
                display: 'flex',
                flexDirection: 'column',
                boxShadow: '2px 2px 10px #ccc',
            },
            submitBtnGrid: {
                display: 'flex',
                flexDirection: 'row-reverse',
                margin: '20px 0',
            },
            submitBtn: {
                background: '#f63',
                color: '#FFF',
                padding: '5px 25px',
                borderRadius: '30px',
                ':hover': {
                    background: '#FFF',
                    color: '#f63',
                }
            },
            addCatForm: {
                display: 'flex',
                flexDirection: 'column',
            },
            confirmContainer: {
                background: '#FFF',
                padding: '20px',
                boxShadow: '5px 5px 5px #ccc',
                borderRadius: '10px',
                display: 'flex',
                flexDirection: 'column !important',
            },
            confirmText: {
                '& h4': {
                    fontFamily: 'Tajawal,sans-serif',
                }

            },
            confirmAction: {
                display: 'flex',
                flexWrap: 'nowrap',
                justifyContent: 'space-between',
                padding: '25px',
                '&>button': {
                    borderRadius: 20,
                    boxShadow: '5px 5px 5px #ccc',
                    fontFamily: 'Tajawal,sans-serif',

                },
                '&>button:first-of-type': {
                    background: '#FFF',
                    color: '#ff6633',
                    width: '100px',
                },
                '&>button:last-child': {
                    background: '#ff6633',
                    color: '#FFF',
                    width: '100px',
                }
            },

            confirmBtnCancel: {
                // color: '#ff6633',
            },
            btnSecondary: {
                borderRadius: 30,
                backgroundColor: '#fff',
                color: '#ff6633',
                borderRadius: '30px',
                ':hover': {
                    backgroundColor: '#ff6633',
                    color: '#FFF',
                },
            },
            saveBtn: {
                padding: '5px 40px'
            },
            // submitBtn: {
            //     background: '#f27021 !important',
            //     color: '#FFF !important',
            //     fontFamily: 'Tajawal, sans-serif !important',
            //     borderRadius: '10px'
            // },
            cancelBtn: {
                background: '#FFF !important',
                color: '#f27021 !important',
                border: '1px solid #f27021 !important',
                fontFamily: 'Tajawal, sans-serif !important',
                borderRadius: '10px'
            },
            card: {
                cursor: 'pointer',
                padding: '20px',
                transition: 'background-color 0.3s ease',
                '&:hover': {
                    // backgroundColor: '#FFCB00',
                    cursor: 'pointer',
                    border: '1.5px solid rgb(255, 97, 80)'
                },
            },
            icon: {
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            },
            textContainer: {
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
            },
            headerCellRight: {
                borderTopRightRadius: '10px',
                borderBottomRightRadius: '10px',
                overflow: 'hidden'
            },
            headerCellLeft: {
                borderTopLeftRadius: '10px',
                borderBottomLeftRadius: '10px',
                borderBottom: "none"
            },
            btns: {
                color: '#f27021',
            },

        })
    );

    const { classes } = useStyles();

    const handleChangeTabValue = (event, newValue) => {
        setTabValue(newValue);
    };

    const handlePrimaryColorClick = () => {
        setDisplayPrimaryColorPicker(displayColorPicker => !displayColorPicker);
    };

    const handlePrimaryColorClose = () => {
        setDisplayPrimaryColorPicker(false);
    };
    const handleSecondaryColorClick = () => {
        setDisplaySecondaryColorPicker(displayColorPicker => !displayColorPicker);
    };

    const handleSecondaryColorClose = () => {
        setDisplaySecondaryColorPicker(false);
    };

    const savePersonal = async () => {
        console.log(accountData);
        await setDoc(doc(db, "Companies", id), accountData).then(() => {
            props.setData(accountData);
            console.log('settings updated');
            setSnackBar({
                isOpen: true,
                message: 'تم حفظ الإعدادات بنجاح',
            });
        });
    }
    const popover = {
        position: 'absolute',
        zIndex: '2',
    }
    const cover = {
        position: 'fixed',
        top: '0px',
        right: '0px',
        bottom: '0px',
        left: '0px',
    }
    const getDetails = () => {
        console.log(data);
        setName(data?.personalProfile?.personalData?.name);
        setUsername(data?.personalProfile?.personalData?.username);
        setAddress(data?.personalProfile?.companyData?.address);
        setCity(data?.personalProfile?.companyData?.city);
        setCountry(data?.personalProfile?.companyData?.country);
        setWebsite(data?.personalProfile?.companyData?.website);
        setCompany(data?.personalProfile?.companyData?.company);

        setSender(data?.settings?.senderSettings?.name);
        setSenderEmail(data?.settings?.senderSettings?.email);
        setToPreview(data?.settings?.senderSettings?.toPreview);
        setAwsSES(data?.settings?.senderSettings?.aws?.ses);
        setAwsCW(data?.settings?.senderSettings?.aws?.cw);

        setPrimaryColor(data?.settings?.view?.primaryColor);
        setSecondaryColor(data?.settings?.view?.secondaryColor);
        setLogo(data?.settings?.view?.logo);
        setFooterLogo(data?.settings?.footer?.footerLogo);
        setFooterText(data?.settings?.footer?.text);
        setFooterSlogonText(data?.settings?.footer?.slogon);
        setFooterCopyrightText(data?.settings?.footer?.copyrightText);
        setFooterUnsubscribeText(data?.settings?.footer?.unsubscribeText);
        setFacebook(data?.settings?.footer?.socialMedia?.facebook);
        setInstagram(data?.settings?.footer?.socialMedia?.instagram);
        setTwitter(data?.settings?.footer?.socialMedia?.twitter);
        setYoutube(data?.settings?.footer?.socialMedia?.youtube);

        setPackage({
            type: data?.settings?.package?.type == 'free' ? 'تجريبية' : 'غير تجريبية',
            active: data?.settings?.package?.active ? 'نشط' : 'غير نشط',
            sentEmails: data?.settings?.package?.sentEmails,
            totalEmails: data?.settings?.package?.totalEmails
        })
    };
    const handleUpgradePlan = () => {
        setOpenUpgradePlan(false);
        console.log(selectedPlanByUserToUpgrade);
    }
    const handleCloseUpgradePlan = () => {
        setOpenUpgradePlan(false);
    }
    useEffect(() => {
        getDetails();
    }, [company, id]);


    const handleGridClick = (index) => {
        setSelectedGrid(index);
    };

    const handleFontStyle = (event) => {
        setFontStyle(event.target.value);
    };
    const CircularProgressWithLabel = (props) => {
        return (
            <Box sx={{ position: 'relative', display: 'inline-flex' }}>
                <CircularProgress
                    variant="determinate" {...props}
                    value={100}
                    size={90}
                    thickness={4}
                    sx={{ color: '#d3d3d3' }} />
                <CircularProgress
                    variant="determinate"
                    {...props}
                    size={90}
                    thickness={6}
                    sx={{
                        position: 'absolute', left: 0, color: '#FFCB00',
                        '& .MuiCircularProgress-circle': {
                            strokeLinecap: 'round', // Make the end of the stroke rounded
                        },
                    }}
                />
                <Box
                    sx={{
                        top: 0,
                        left: 0,
                        bottom: 0,
                        right: 0,
                        position: 'absolute',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                >
                    <div>
                        <Typography variant="caption" component="div" color="textSecondary" align='center' sx={{ fontSize: '10px', }}>
                            {_package.sentEmails}/{_package.totalEmails}
                        </Typography>
                        <Typography variant="body2" align='center' sx={{ fontSize: '10px', }} >
                            نشرة
                        </Typography>
                    </div>
                </Box>
            </Box>
        );
    };
    const currentDate = format(new Date(), 'dd MMM yyyy');

    const renderContent = () => {
        switch (selectedGrid) {
            case 0:
                return <>
                    <Grid container>
                        <Grid item xs={12}>
                            <Typography variant="body2" sx={{ fontWeight: 'bold' }}>معلومات شخصية </Typography>
                            <Typography variant="body2" sx={{ fontSize: "10px" }}> معلومات الحساب الرئيسي في موجز لا يمكن التعديل عليها </Typography>
                        </Grid>
                    </Grid>
                    <Grid container spacing={2} mt={3}>
                        <Grid item md={6}>
                            <TextField
                                size='small'
                                fullWidth
                                value={username}
                                variant="outlined"
                                label="الاسم الاول"
                                // margin="dense"
                                inputProps={
                                    { readOnly: true, }
                                }
                                sx={{
                                    '& .MuiInputBase-root': {
                                        fontSize: '0.7rem', // Smaller font size
                                    },
                                    '& .MuiInputLabel-root': {
                                        fontSize: '0.7rem', // Smaller label font size
                                    },
                                    '& .MuiOutlinedInput-root': {
                                        '& fieldset': {
                                            borderRadius: '7px', // Adjust border radius
                                        },
                                    },
                                }}

                            />
                        </Grid>
                        <Grid item md={6}>
                            <TextField
                                size='small'
                                fullWidth
                                value={name}
                                onChange={({ target: { value } }) => setName(value)}
                                variant="outlined"
                                label="الاسم الاخير"
                                inputProps={
                                    { readOnly: true, }
                                }
                                sx={{
                                    '& .MuiInputBase-root': {
                                        fontSize: '0.7rem', // Smaller font size
                                    },
                                    '& .MuiInputLabel-root': {
                                        fontSize: '0.7rem', // Smaller label font size
                                    },
                                    '& .MuiOutlinedInput-root': {
                                        '& fieldset': {
                                            borderRadius: '7px', // Adjust border radius
                                        },
                                    },
                                }}
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={2} mt={1}>
                        <Grid item md={12}>
                            <TextField
                                size='small'
                                fullWidth
                                value={username}
                                // onChange={({ target: { value } }) => setName(value)}
                                variant="outlined"
                                label="البريد الالكتروني"
                                margin="dense"
                                inputProps={
                                    { readOnly: true, }
                                }
                                sx={{
                                    '& .MuiInputBase-root': {
                                        fontSize: '0.7rem', // Smaller font size
                                    },
                                    '& .MuiInputLabel-root': {
                                        fontSize: '0.7rem', // Smaller label font size
                                    },
                                    '& .MuiOutlinedInput-root': {
                                        '& fieldset': {
                                            borderRadius: '7px', // Adjust border radius
                                        },
                                    },
                                }}
                            />
                        </Grid>
                    </Grid>
                    <Divider sx={{ borderBottomWidth: 1, borderColor: 'rgb(0 0 0 / 45%)', margin: '25px 0' }} />
                    <Grid container>
                        <Grid item xs={12}>
                            <Typography variant="body2" sx={{ fontWeight: 'bold' }}>معلومات الشركة </Typography>
                            <Typography variant="body2" sx={{ fontSize: "10px" }}> معلومات الحساب الرئيسي في موجز لا يمكن التعديل عليها </Typography>
                        </Grid>
                    </Grid>
                    <Grid container spacing={2} mt={3}>
                        <Grid item md={6}>
                            <TextField
                                size='small'
                                value={company}
                                //onChange={({ target: { value } }) => setCompany(value)}
                                fullWidth
                                variant="outlined"
                                label="اسم الشركة"
                                sx={{
                                    '& .MuiInputBase-root': {
                                        fontSize: '0.7rem', // Smaller font size
                                    },
                                    '& .MuiInputLabel-root': {
                                        fontSize: '0.7rem', // Smaller label font size
                                    },
                                    '& .MuiOutlinedInput-root': {
                                        '& fieldset': {
                                            borderRadius: '7px', // Adjust border radius
                                        },
                                    },
                                }}

                            />
                        </Grid>
                        <Grid item md={6}>
                            <TextField
                                size='small'
                                fullWidth
                                value={website}
                                onChange={({ target: { value } }) => setWebsite(value)}
                                variant="outlined"
                                label="البريد الالكتروني"
                                sx={{
                                    '& .MuiInputBase-root': {
                                        fontSize: '0.7rem', // Smaller font size
                                    },
                                    '& .MuiInputLabel-root': {
                                        fontSize: '0.7rem', // Smaller label font size
                                    },
                                    '& .MuiOutlinedInput-root': {
                                        '& fieldset': {
                                            borderRadius: '7px', // Adjust border radius
                                        },
                                    },
                                }}
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={2} mt={1}>
                        <Grid item md={12}>
                            <TextField
                                size='small'
                                fullWidth
                                value={address}
                                onChange={({ target: { value } }) => setAddress(value)}
                                variant="outlined"
                                label="العنوان"
                                margin="dense"
                                sx={{
                                    '& .MuiInputBase-root': {
                                        fontSize: '0.7rem', // Smaller font size
                                    },
                                    '& .MuiInputLabel-root': {
                                        fontSize: '0.7rem', // Smaller label font size
                                    },
                                    '& .MuiOutlinedInput-root': {
                                        '& fieldset': {
                                            borderRadius: '7px', // Adjust border radius
                                        },
                                    },
                                }}
                            />
                        </Grid>
                    </Grid>
                    <Grid container mt={2}>
                        <Grid item xs={12} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                            <Button size='small' style={{ backgroundColor: '#FF6150', borderRadius: '7px' }} variant="contained" onClick={savePersonal}>حفظ</Button>
                        </Grid>
                    </Grid>
                </>;
            case 1:
                return <>
                    {/* إعدادات المرسل */}
                    <Grid container>
                        <Grid item md={12}>
                            <Typography variant="body2" sx={{ fontWeight: 'bold' }}>إعدادات المرسل</Typography>
                            <Typography variant="body2" sx={{ fontSize: "10px" }}>  معلومات الحساب الرئيسي في موجز لا يمكن التعديل عليها</Typography>
                        </Grid>
                    </Grid>
                    <Grid container spacing={1.5} mt={2} columns={{ xs: 1, sm: 1, md: 12 }}>
                        <Grid item md={5}>
                            <TextField
                                size='small'
                                fullWidth
                                value={sender}
                                onChange={({ target: { value } }) => setSender(value)}
                                variant="outlined"
                                label="اسم المرسل"
                                // margin="dense"
                                inputProps={
                                    { readOnly: true, }
                                }
                                sx={{
                                    '& .MuiInputBase-root': {
                                        fontSize: '0.7rem', // Smaller font size
                                    },
                                    '& .MuiInputLabel-root': {
                                        fontSize: '0.7rem', // Smaller label font size
                                    },
                                    '& .MuiOutlinedInput-root': {
                                        '& fieldset': {
                                            borderRadius: '7px', // Adjust border radius
                                        },
                                    },
                                }}

                            />
                        </Grid>
                        <Grid item md={5}>
                            <TextField
                                size='small'
                                fullWidth
                                value={senderEmail}
                                onChange={({ target: { value } }) => setSenderEmail(value)}
                                variant="outlined"
                                label="البريد الالكتروني للمرسل"
                                inputProps={
                                    { readOnly: true, }
                                }
                                sx={{
                                    '& .MuiInputBase-root': {
                                        fontSize: '0.7rem', // Smaller font size
                                    },
                                    '& .MuiInputLabel-root': {
                                        fontSize: '0.7rem', // Smaller label font size
                                    },
                                    '& .MuiOutlinedInput-root': {
                                        '& fieldset': {
                                            borderRadius: '7px', // Adjust border radius
                                        },
                                    },
                                }}
                            />
                        </Grid>
                        <Grid item md={2} >
                            <Button size='small' style={{ backgroundColor: '#FF6150', borderRadius: '7px' }} variant="contained" >تأكيد</Button>
                        </Grid>
                    </Grid>
                    <Grid container spacing={1.5} columns={{ xs: 1, sm: 1, md: 12 }}>
                        <Grid item md={5}>
                            <FormControlLabel
                                control={<Checkbox defaultChecked />}
                                label="عرض اسم المرسل في خانة to عند المستلم"
                                labelPlacement="end"  // Position label to the left
                                sx={{
                                    display: "block",
                                    '& .MuiSvgIcon-root': { fontSize: 20 },
                                    '& .MuiFormControlLabel-label': { fontSize: '10px', fontWeight: 'bold' },
                                    '&.Mui-checked': {
                                        color: '#FF6150',
                                    },
                                }}  // Optional: Adjust margin if needed
                            />
                        </Grid>
                        <Grid item md={5} mt={3}>
                            <TextField
                                size='small'
                                fullWidth
                                // value={username}
                                variant="outlined"
                                label="الموقع الالكتروني"
                                helperText="اضافة عنوان النطاق الالكتروني والتحقق منه يساعدك على الوصول الى بريدك بشكل امن"
                                inputProps={
                                    { readOnly: true, }
                                }
                                sx={{
                                    '& .MuiInputBase-root': {
                                        fontSize: '0.7rem', // Smaller font size
                                    },
                                    '& .MuiInputLabel-root': {
                                        fontSize: '0.7rem', // Smaller label font size
                                    },
                                    '& .MuiOutlinedInput-root': {
                                        '& fieldset': {
                                            borderRadius: '7px', // Adjust border radius
                                        },
                                    },
                                    '& .MuiFormHelperText-root': {
                                        color: '#FF6150', // Change helper text color
                                        margin: '0px',
                                        fontSize: '0.6rem',
                                        padding: '2px'
                                    },
                                }}
                            />
                        </Grid>
                        <Grid item md={2} mt={3}>
                            <Button size='small' style={{ backgroundColor: '#FF6150', borderRadius: '7px' }} variant="contained" >تأكيد</Button>
                        </Grid>
                    </Grid>
                    <Divider sx={{ borderBottomWidth: 1, borderColor: 'rgb(0 0 0 / 45%)', margin: '25px 0' }} />

                    {/* المظهر */}
                    <Grid container>
                        <Grid item md={12}>
                            <Typography variant="body2" sx={{ fontWeight: 'bold' }}>المظهر</Typography>
                            <Typography variant="body2" sx={{ fontSize: "10px" }}>تغيير الالوان الاساسية وشعار الشركة بالاضافة الى اختيار الخطوط</Typography>
                        </Grid>
                    </Grid>
                    <Grid container mt={2} spacing={1.5} columns={{ xs: 1, sm: 1, md: 12 }}>
                        <Grid item md={5}>
                            <Typography variant="body2" sx={{ fontWeight: 'bold', fontSize: "12px" }}>شعار الشركة</Typography>
                            <Typography variant="body2" sx={{ fontSize: "10px", fontWeight: 100, width: "250px" }}>سيتم عرض هذا الشعار في اي مكان لديك فيه عنصر يدل على الهوية مثل ترويسات وتذييل والحساب الرئيسي</Typography>
                        </Grid>
                        <Grid item md={5}>
                            <Typography variant="body2" sx={{ fontWeight: 'bold', fontSize: "12px" }}>شعار التذييل</Typography>
                            <Typography variant="body2" sx={{ fontSize: "10px", fontWeight: 100, width: "250px" }}>سيتم عرض هذا الشعار في اي مكان لديك فيه عنصر يدل على الهوية مثل ترويسات وتذييل والحساب الرئيسي</Typography>
                        </Grid>
                    </Grid>
                    <Grid container mt={2} spacing={1.5} columns={{ xs: 1, sm: 1, md: 12 }}>
                        <Grid item md={5}>
                            <ImageUploaderSetting image={logo} textImage={"+"} onChange={setLogo} />
                        </Grid>
                        <Grid item md={5}>
                            <ImageUploaderSetting image={footerLogo} textImage={"+"} onChange={setFooterLogo} />
                        </Grid>
                    </Grid>
                    <Grid container mt={2} spacing={1.5} columns={{ xs: 1, sm: 1, md: 12 }}>
                        <Grid item md={5}>
                            <Typography variant="body2" sx={{ fontWeight: 'bold', fontSize: "12px" }}>اللون الرئيسي</Typography>
                            <Typography variant="body2" style={{ fontSize: "10px", fontWeight: 100 }}>عرض لون رئيسي للعناوين والتذييل</Typography>
                            <Button style={{ background: primaryColor?.hex, width: '50px', height: '60px', borderRadius: '50%', marginTop: '16px' }} size="large" variant="outlined" onClick={handlePrimaryColorClick}>  </Button>
                            {displayPrimaryColorPicker ? <div style={popover}>
                                <div style={cover} onClick={handlePrimaryColorClose} />
                                <ChromePicker
                                    style={{ direction: 'ltr' }}
                                    color={primaryColor}
                                    onChange={(color) => setPrimaryColor(color)} />
                            </div> : null}
                        </Grid>
                        <Grid item md={5}>
                            <Typography variant="body2" sx={{ fontWeight: 'bold', fontSize: "12px" }}>اللون الثانوي</Typography>
                            <Typography variant="body2" style={{ fontSize: "10px", fontWeight: 100 }}>عرض لون ثانوي للعناوين والتذييل</Typography>
                            <Button style={{ background: secondaryColor?.hex, width: '50px', height: '60px', borderRadius: '50%', marginTop: '16px' }} size="large" variant="outlined" onClick={handleSecondaryColorClick}>  </Button>
                            {displaySecondaryColorPicker ? <div style={popover}>
                                <div style={cover} onClick={handleSecondaryColorClose} />
                                <ChromePicker
                                    style={{ direction: 'ltr' }}
                                    color={secondaryColor}
                                    onChange={(color) => setSecondaryColor(color)} />
                            </div> : null}
                        </Grid>
                    </Grid>
                    <Grid container spacing={1.5} mt={2} columns={{ xs: 1, sm: 1, md: 12 }}>
                        <Grid item md={5}>
                            <Typography variant="body2" style={{ fontWeight: '5px', fontSize: '12px' }}>اختار الخط الرئيسي للعناوين</Typography>
                            <FormControl sx={{ mt: 2, minWidth: 250, }} size="small">
                                <InputLabel id="demo-simple-select-filled-label">Font style</InputLabel>
                                <Select
                                    //styles={{ option: (provided, state) => ({ ...provided, fontFamily: state.value }) }}
                                    name="form-field-name"
                                    onChange={handleFontStyle}
                                    label={fontStyle}
                                    displayEmpty
                                    inputProps={{ 'aria-label': 'Without label' }}
                                >
                                    <MenuItem value={'Arial'}>Arial</MenuItem>
                                    <MenuItem value={'Times New Roman'}>Times New Roman</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item md={5}>
                            <Typography variant="body2" style={{ fontWeight: '5px', fontSize: '12px' }}>اختار الخط الثانوي للنصوص</Typography>
                            <FormControl sx={{ mt: 2, minWidth: 250 }} size="small">
                                <InputLabel id="demo-simple-select-filled-label">Font style</InputLabel>
                                <Select
                                    //styles={{ option: (provided, state) => ({ ...provided, fontFamily: state.value }) }}
                                    name="form-field-name"
                                    onChange={handleFontStyle}
                                    label={fontStyle}
                                    displayEmpty
                                    inputProps={{ 'aria-label': 'Without label' }}
                                >
                                    <MenuItem value={'Arial'}>Arial</MenuItem>
                                    <MenuItem value={'Times New Roman'}>Times New Roman</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                    </Grid>
                    <Divider sx={{ borderBottomWidth: 1, borderColor: 'rgb(0 0 0 / 45%)', margin: '25px 0' }} />

                    {/* التذييل */}
                    <Grid container>
                        <Grid item md={12}>
                            <Typography variant="body2" sx={{ fontWeight: 'bold' }}>التذييل</Typography>
                            <Typography variant="body2" sx={{ fontSize: "10px" }}>النص الخاص بالتذييل والحقوق والغاء الاشتراك</Typography>
                        </Grid>
                        <Grid container mt={2} >
                            <Grid item md={11}>
                                <Typography variant="body2" sx={{ fontSize: "12px" }}>نص التذييل</Typography>
                                <TextField
                                    size='small'
                                    fullWidth
                                    value={footerText}
                                    onChange={({ target: { value } }) => setFooterText(value)}
                                    variant="outlined"
                                    //label="الاسم الاول"
                                    placeholder="النص الذي يكون في اسفل النشرة عبارة عن شرح عن النشرة"
                                    inputProps={
                                        { readOnly: true, }
                                    }
                                    sx={{
                                        '& .MuiInputBase-root': {
                                            fontSize: '0.7rem', // Smaller font size
                                        },
                                        '& .MuiInputLabel-root': {
                                            fontSize: '0.7rem', // Smaller label font size
                                        },
                                        '& .MuiOutlinedInput-root': {
                                            '& fieldset': {
                                                borderRadius: '7px', // Adjust border radius
                                            },
                                        },
                                        paddingTop: "5px",

                                    }}


                                />
                            </Grid>
                        </Grid>
                        <Grid container mt={2} >
                            <Grid item md={11}>
                                <Typography variant="body2" sx={{ fontSize: "12px" }}>الحقوق</Typography>
                                <TextField
                                    size='small'
                                    fullWidth
                                    value={footerCopyrightText}
                                    onChange={({ target: { value } }) => setFooterCopyrightText(value)}
                                    variant="outlined"
                                    //label="الاسم الاول"
                                    placeholder="عن حقوق النشر"
                                    inputProps={
                                        { readOnly: true, }
                                    }
                                    sx={{
                                        '& .MuiInputBase-root': {
                                            fontSize: '0.7rem', // Smaller font size
                                        },
                                        '& .MuiInputLabel-root': {
                                            fontSize: '0.7rem', // Smaller label font size
                                        },
                                        '& .MuiOutlinedInput-root': {
                                            '& fieldset': {
                                                borderRadius: '7px', // Adjust border radius
                                            },
                                        },
                                        paddingTop: "5px",

                                    }}


                                />
                            </Grid>
                        </Grid>
                        <Grid container mt={2} >
                            <Grid item md={11}>
                                <Typography variant="body2" sx={{ fontSize: "12px" }}>الغاء الاشتراك</Typography>
                                <TextField
                                    size='small'
                                    fullWidth
                                    value={footerUnsubscribeText}
                                    onChange={({ target: { value } }) => setFooterUnsubscribeText(value)}
                                    variant="outlined"
                                    //label="الاسم الاول"
                                    placeholder=" قم بوضع نص الخاص بالغاء الاشتراك"
                                    inputProps={
                                        { readOnly: true, }
                                    }
                                    sx={{
                                        '& .MuiInputBase-root': {
                                            fontSize: '0.7rem', // Smaller font size
                                        },
                                        '& .MuiInputLabel-root': {
                                            fontSize: '0.7rem', // Smaller label font size
                                        },
                                        '& .MuiOutlinedInput-root': {
                                            '& fieldset': {
                                                borderRadius: '7px', // Adjust border radius
                                            },
                                        },
                                        paddingTop: "5px",

                                    }}


                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Divider sx={{ borderBottomWidth: 1, borderColor: 'rgb(0 0 0 / 45%)', margin: '25px 0' }} />

                    {/* التواصل الاجتماعي */}
                    <Grid container>
                        <Grid item md={12}>
                            <Typography variant="body2" sx={{ fontWeight: 'bold' }}>التواصل الاجتماعي</Typography>
                            <Typography variant="body2" sx={{ fontSize: "10px" }}></Typography>
                        </Grid>
                        <Grid container mt={2} >
                            <Grid item md={11}>
                                <Typography variant="body2" sx={{ fontSize: "12px" }}>فيسبوك</Typography>
                                <TextField
                                    size='small'
                                    fullWidth
                                    value={facebook}
                                    onChange={({ target: { value } }) => setFacebook(value)}
                                    variant="outlined"
                                    //label="فايسبوك"
                                    placeholder="قم بوضع الرابط هنا"
                                    inputProps={
                                        { readOnly: true, }
                                    }
                                    sx={{
                                        '& .MuiInputBase-root': {
                                            fontSize: '0.7rem', // Smaller font size
                                        },
                                        '& .MuiInputLabel-root': {
                                            fontSize: '0.7rem', // Smaller label font size
                                        },
                                        '& .MuiOutlinedInput-root': {
                                            '& fieldset': {
                                                borderRadius: '7px', // Adjust border radius
                                            },
                                        },
                                        paddingTop: "5px",

                                    }}


                                />
                            </Grid>
                        </Grid>
                        <Grid container mt={2} >
                            <Grid item md={11}>
                                <Typography variant="body2" sx={{ fontSize: "12px" }}>انستقرام</Typography>
                                <TextField
                                    size='small'
                                    fullWidth
                                    value={instagram}
                                    onChange={({ target: { value } }) => setInstagram(value)}
                                    variant="outlined"
                                    //label="إنستاغرام"
                                    placeholder="قم بوضع الرابط هنا"
                                    inputProps={
                                        { readOnly: true, }
                                    }
                                    sx={{
                                        '& .MuiInputBase-root': {
                                            fontSize: '0.7rem', // Smaller font size
                                        },
                                        '& .MuiInputLabel-root': {
                                            fontSize: '0.7rem', // Smaller label font size
                                        },
                                        '& .MuiOutlinedInput-root': {
                                            '& fieldset': {
                                                borderRadius: '7px', // Adjust border radius
                                            },
                                        },
                                        paddingTop: "5px",

                                    }}


                                />
                            </Grid>
                        </Grid>
                        <Grid container mt={2} >
                            <Grid item md={11}>
                                <Typography variant="body2" sx={{ fontSize: "12px" }}>اكس( تويتر)</Typography>
                                <TextField
                                    size='small'
                                    fullWidth
                                    value={twitter}
                                    onChange={({ target: { value } }) => setTwitter(value)}
                                    variant="outlined"
                                    //label="تويتر"
                                    placeholder="قم بوضع الرابط هنا"
                                    inputProps={
                                        { readOnly: true, }
                                    }
                                    sx={{
                                        '& .MuiInputBase-root': {
                                            fontSize: '0.7rem', // Smaller font size
                                        },
                                        '& .MuiInputLabel-root': {
                                            fontSize: '0.7rem', // Smaller label font size
                                        },
                                        '& .MuiOutlinedInput-root': {
                                            '& fieldset': {
                                                borderRadius: '7px', // Adjust border radius
                                            },
                                        },
                                        paddingTop: "5px",

                                    }}


                                />
                            </Grid>
                        </Grid>
                        <Grid container mt={2} >
                            <Grid item md={11}>
                                <Typography variant="body2" sx={{ fontSize: "12px" }}>يوتيوب</Typography>
                                <TextField
                                    size='small'
                                    fullWidth
                                    // value={username}
                                    variant="outlined"
                                    //label="الاسم الاول"
                                    placeholder="قم بوضع الرابط هنا"
                                    sx={{
                                        '& .MuiInputBase-root': {
                                            fontSize: '0.7rem', // Smaller font size
                                        },
                                        '& .MuiInputLabel-root': {
                                            fontSize: '0.7rem', // Smaller label font size
                                        },
                                        '& .MuiOutlinedInput-root': {
                                            '& fieldset': {
                                                borderRadius: '7px', // Adjust border radius
                                            },
                                        },
                                        paddingTop: "5px",

                                    }}


                                />
                            </Grid>
                        </Grid>
                        <Grid container mt={3}>
                            <Grid item md={11} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                <Button size='small' style={{ backgroundColor: '#072448' }} variant="contained" onClick={savePersonal}>حفظ</Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </>;
            case 2:
                return <Users />;
            case 3:
                return <>
                    <Grid container>
                        <Grid item md={12}>
                            <Typography variant="body2" sx={{ fontWeight: 'bold' }}>الباقة والرصيد</Typography>
                            <Typography variant="body2" sx={{ fontSize: "10px" }}>  معلومات الباقة والرصيد في حسابك</Typography>
                        </Grid>
                    </Grid>
                    <Grid container spacing={2} mt={2} columns={{ xs: 1, sm: 1, md: 12 }}>
                        <Grid item md={5}>

                            <Box sx={{
                                // display: 'flex',
                                // alignItems: 'center',
                                flexDirection: 'column',
                                border: '1px solid',
                                borderColor: 'divider',
                                borderRadius: 2,
                            }}>
                                <Box sx={{ display: 'flex', width: '100%', padding: '16px' }}>
                                    <Typography variant="body2" sx={{ fontSize: '12px', fontWeight: 'bold', color: 'red', }}>
                                        الباقة الثابتة
                                        <span style={{ fontSize: '10px', color: '#072448', fontWeight: 'normal' }}>
                                            {" " + currentDate}
                                        </span>
                                    </Typography>
                                </Box>
                                <Box sx={{ display: 'flex', alignItems: 'center', flexDirection: 'column', mt: 2 }}>
                                    <CircularProgressWithLabel value={(_package.sentEmails / _package.totalEmails) * 100} />

                                    <Button size='small' style={{ backgroundColor: '#54D2D2', marginTop: '16px', color: 'white', width: '50%' }} onClick={() => setOpenUpgradePlan(true)}>ترقية الباقة</Button>

                                    <Divider flexItem style={{ height: 'auto', marginTop: '16px' }} />
                                    <Box sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'space-around',
                                        width: '100%',

                                    }}>
                                        <Grid container
                                            sx={{
                                                flexDirection: 'column',
                                                alignItems: 'center', // Center items vertically within each column

                                            }}
                                        >
                                            <Grid item sx={{ padding: '5px' }}>
                                                <Typography variant="body2" sx={{ fontSize: '10px', textAlign: 'center' }}>   المستهلك</Typography>
                                                <Typography variant="body2" sx={{ fontSize: '10px', textAlign: 'center' }}>{_package.totalEmails}</Typography>
                                            </Grid>
                                        </Grid>
                                        <Divider orientation="vertical" flexItem style={{ height: 'auto' }} />
                                        <Grid container
                                            sx={{
                                                flexDirection: 'column',
                                                alignItems: 'center', // Center items vertically within each column
                                            }}
                                        >
                                            <Grid item sx={{ padding: '5px' }}>
                                                <Typography variant="body2" sx={{ fontSize: "10px", textAlign: 'center' }}>  الباقي </Typography>
                                                <Typography variant="body2" sx={{ fontSize: '10px', textAlign: 'center' }}>{_package.sentEmails}</Typography>
                                            </Grid>
                                        </Grid>
                                    </Box>

                                </Box>
                            </Box>
                        </Grid>
                        <Grid item md={5}>

                            <Box sx={{
                                // display: 'flex',
                                // alignItems: 'center',
                                flexDirection: 'column',
                                border: '1px solid',
                                borderColor: 'divider',
                                borderRadius: 2,
                            }}>
                                <Box sx={{ display: 'flex', width: '100%', padding: '16px' }}>
                                    <Typography variant="body2" sx={{ fontSize: '12px', fontWeight: 'bold', color: 'red', }}>
                                        رصيد الاعلانات
                                        <span style={{ fontSize: '10px', color: '#072448', fontWeight: 'normal' }}>
                                            {" " + currentDate}
                                        </span>
                                    </Typography>
                                </Box>
                                <Box sx={{ display: 'flex', alignItems: 'center', flexDirection: 'column', mt: 2 }}>
                                    <CircularProgressWithLabel value={(_package.sentEmails / _package.totalEmails) * 100} />

                                    <Button size='small' style={{ backgroundColor: '#54D2D2', marginTop: '16px', color: 'white', width: '50%' }} onClick={() => setOpenUpgradePlan(true)}>سحب</Button>

                                    <Divider flexItem style={{ height: 'auto', marginTop: '16px' }} />
                                    <Box sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'space-around',
                                        width: '100%',

                                    }}>
                                        <Grid container
                                            sx={{
                                                flexDirection: 'column',
                                                alignItems: 'center', // Center items vertically within each column

                                            }}
                                        >
                                            <Grid item sx={{ padding: '5px' }}>
                                                <Typography variant="body2" sx={{ fontSize: '10px', textAlign: 'center' }}>   الرصيد القابل للسحب</Typography>
                                                <Typography variant="body2" sx={{ fontSize: '10px', textAlign: 'center' }}>{_package.totalEmails}</Typography>
                                            </Grid>
                                        </Grid>
                                        <Divider orientation="vertical" flexItem style={{ height: 'auto' }} />
                                        <Grid container
                                            sx={{
                                                flexDirection: 'column',
                                                alignItems: 'center', // Center items vertically within each column
                                            }}
                                        >
                                            <Grid item sx={{ padding: '5px' }}>
                                                <Typography variant="body2" sx={{ fontSize: "10px", textAlign: 'center' }}>   الرصد الحالي </Typography>
                                                <Typography variant="body2" sx={{ fontSize: '10px', textAlign: 'center' }}>{_package.sentEmails}</Typography>
                                            </Grid>
                                        </Grid>
                                    </Box>

                                </Box>
                            </Box>
                        </Grid>
                    </Grid >
                    <Divider sx={{ marginTop: '25px', marginBottom: '25px', borderBottomWidth: 1 }} />
                    <TableContainer component={Paper} style={{ boxShadow: 'none', }}>
                        <Table sx={{ minWidth: 500 }} aria-label="simple table">
                            <TableHead sx={{ background: '#F5F6FA', borderRadius: '10px', overflow: 'hidden' }}>
                                <TableRow sx={{
                                    background: '#F5F6FA',
                                    '&:first-of-type': {
                                        borderTopLeftRadius: '10px',
                                        borderTopRightRadius: '10px',
                                    }
                                }}>
                                    <TableCell className={classes.headerCellRight} sx={{ borderBottom: "none", }} align="left">
                                        <Typography variant="body2" sx={{ fontSize: 'small' }}>
                                            العملية
                                        </Typography>
                                    </TableCell>
                                    <TableCell sx={{ borderBottom: "none" }} align="center">
                                        <Typography variant="body2" sx={{ fontSize: 'small' }}>
                                            التاريخ
                                        </Typography>
                                    </TableCell>

                                    <TableCell sx={{ borderBottom: 'none' }} align="center">
                                        <Typography variant="body2" sx={{ fontSize: 'small' }}>
                                            النوع
                                        </Typography>
                                    </TableCell>
                                    <TableCell align="right" className={classes.headerCellLeft} sx={{ borderBottom: "none" }} >
                                        <Typography variant="body2" sx={{ fontSize: 'small' }}>
                                            الاجراءات
                                        </Typography>
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {/* {items.map((row, index) => {
                                    if (index < currentPage * currentLimit && (currentPage === 1 ? true : index >= currentPage * currentLimit - 10)) {
                                        return (

                                            <TableRow
                                                key={index}
                                                index={index}
                                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                            >
                                                <TableCell component="th" scope="row" sx={{ borderBottom: "none", }} align="left">
                                                    <Typography variant="body2" sx={{ fontSize: 'small' }}>
                                                        {row.name}
                                                    </Typography>
                                                </TableCell>
                                                <TableCell component="th" scope="row" sx={{ borderBottom: "none", }} align="center">
                                                    <Typography variant="body2" sx={{ fontSize: 'small' }}>
                                                        {row.email}
                                                    </Typography>
                                                </TableCell>
                                                <TableCell component="th" scope="row" sx={{ borderBottom: "none", }} align="center">
                                                    <Typography variant="body2" sx={{ fontSize: 'small' }}>
                                                        {row.responsibility}
                                                    </Typography>
                                                </TableCell>
                                                <TableCell sx={{ padding: '0px', borderBottom: 'none', }} align="center">
                                                    <IconButton aria-label="edit" className={classes.btns} onClick={() => handleUserVisible(index, row)}>
                                                        <Edit style={{ fontSize: '1rem' }} />
                                                    </IconButton>
                                                    <IconButton aria-label="delete" className={classes.btns} onClick={() => handleDeleteUser(row)}>
                                                        <Delete style={{ fontSize: '1rem' }} />
                                                    </IconButton>
                                                </TableCell>
                                            </TableRow>
                                        )
                                    }
                                }
                                )} */}
                            </TableBody>
                        </Table>
                    </TableContainer>

                </>;
            case 4:
                return <>
                    <Grid container>
                        <Grid item md={5}>
                            <Typography variant="body2" sx={{ fontWeight: 'bold' }}>الربط والتضمين</Typography>
                            <Typography variant="body2" sx={{ fontSize: "10px" }}>  قم بربط موقعك الالكتروني ونموذج التسجيل من هنا</Typography>
                            <Typography variant="body2" sx={{ fontSize: "10px", fontWeight: 'bold', marginTop: '50px' }}>
                                قم بربط موقعك الالكتروني ونموذج التسجيل من هنا
                            </Typography>
                            <Typography variant="body2" sx={{ fontSize: "10px", fontWeight: 200, mt: 1 }}>
                                الكود الربط والتضمين قم بنسخ الكود الخاص بك
                                ووضعه في موقعك الالكتروني من اجل ربطه مع موجز
                                واضافه اي مشترك جديد في نشرتك
                                البريديه تلقائيا في قائمه المشتركين الخاص بك
                            </Typography>


                            <Box sx={{
                                // display: 'flex',
                                // alignItems: 'center',
                                flexDirection: 'column',
                                border: '1px solid',
                                borderColor: 'divider',
                                borderRadius: 1,
                                borderColor: 'black',
                                mt: 2,
                            }}>
                                <Box sx={{ display: 'flex', width: '100%', padding: '15px' }}>
                                    <Typography variant="body2" sx={{ fontSize: "10px", }}>
                                        Le Lorem Ipsum est simplement du faux texte employé
                                        dans la composition et la mise en page avant impression.
                                        Le Lorem Ipsum est le faux texte standard de l'imprimerie
                                        depuis les années 1500, quand un imprimeur anonyme assembla
                                        ensemble des morceaux de texte pour réaliser un livre spécimen
                                        de polices de texte.
                                    </Typography>
                                </Box>
                                <Box sx={{ display: 'flex', justifyContent: 'end', ml: '8px' }}>
                                    <CopyToClipboard text={codeSnippetJS} onCopy={handleCopyOne}>
                                        <IconButton aria-label="delete" color='primary' size='small'>
                                            {isCopiedOne ? <ContentCopyRoundedIcon fontSize='inherit' /> : <ContentCopyIcon fontSize="inherit" />}
                                        </IconButton>
                                    </CopyToClipboard>
                                </Box>
                            </Box>

                        </Grid>
                    </Grid>
                </>;
            default:
                return <Typography variant="h6">No content yet...</Typography>;
        }
    };

    const toggleContent = (index) => {
        setIsOpenContent((isOpenContent) => !isOpenContent)
        handleGridClick(index)
    }
    return (
        <>

            {/* <Grid container>
                <Grid item xs={12}>
                    <Paper elevation={0} className={classes.paperList}>
                        <Grid item xs={9}>
                            <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                                إعدادات الحساب
                            </Typography>
                        </Grid>
                    </Paper>
                </Grid>
            </Grid> */}
            {isOpenContent && <div>
                <Grid container spacing={2} mt={1} columns={{ xs: 1, sm: 1, md: 12 }} justifyContent={'center'}>
                    <Grid item xs={1} sm={1} md={3} >
                        <Paper elevation={0} className={classes.card} onClick={() => toggleContent(0)}>
                            <Grid container spacing={1} columns={{ md: 3 }} alignItems="center">
                                <Grid item md={0.5} className={classes.icon}>
                                    <img src={profileSettingsIcon} width={30} />
                                </Grid>
                                <Grid item md={2.5} >
                                    <Grid container columns={{ md: 2 }} alignItems="center">
                                        <Grid item md={2} >
                                            <Typography variant="body2" sx={{ fontSize: '15px', fontWeight: 'bold' }}>
                                                اعدادات الملف الشخصي
                                            </Typography>
                                        </Grid>
                                        <Grid item md={2}>
                                            <Typography variant="body2" sx={{ fontSize: '10px', color: "EFF0F7" }}>
                                                معلومات شخصية ومعلومات الشركة
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>
                    <Grid item xs={1} sm={1} md={3} >
                        <Paper elevation={0} className={classes.card} onClick={() => toggleContent(1)}>
                            <Grid container spacing={1} columns={{ md: 3 }} alignItems="center">
                                <Grid item md={0.5} className={classes.icon}>
                                    <img src={bulletinSettingsIcon} width={30} />
                                </Grid>
                                <Grid item md={2.5} >
                                    <Grid container columns={{ md: 2 }} alignItems="center">
                                        <Grid item md={2}>
                                            <Typography variant="body2" sx={{ fontSize: '15px', fontWeight: 'bold' }}>
                                                اعدادات النشرة
                                            </Typography>
                                        </Grid>
                                        <Grid item md={2}>
                                            <Typography variant="body2" sx={{ fontSize: '10px', color: "EFF0F7" }}>
                                                اداره البريد المرسل والمظهر العام للنشره
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>
                    <Grid item xs={1} sm={1} md={3} >
                        <Paper elevation={0} className={classes.card} onClick={() => toggleContent(2)}>
                            <Grid container spacing={1} columns={{ md: 3 }} alignItems="center">
                                <Grid item md={0.5} className={classes.icon}>
                                    <img src={userManagementIcon} width={30} />
                                </Grid>
                                <Grid item md={2.5} >
                                    <Grid container columns={{ md: 2 }} alignItems="center">
                                        <Grid item md={2}>
                                            <Typography variant="body2" sx={{ fontSize: '15px', fontWeight: 'bold' }}>
                                                إدارة المستخدمين
                                            </Typography>
                                        </Grid>
                                        <Grid item md={2}>
                                            <Typography variant="body2" sx={{ fontSize: '10px', color: "EFF0F7" }}>
                                                اضافه وحذف المستخدمين وتغيير الصلاحيات
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>
                </Grid>
                <Grid container spacing={2} mt={1} columns={{ xs: 1, sm: 1, md: 12 }} justifyContent={'center'}>
                    <Grid item xs={1} sm={1} md={3} >
                        <Paper elevation={0} className={classes.card} onClick={() => toggleContent(3)}>
                            <Grid container spacing={1} columns={{ md: 3 }} alignItems="center" >
                                <Grid item md={0.5} className={classes.icon}>
                                    <img src={packageIcon} width={30} />
                                </Grid>
                                <Grid item md={2.5} >
                                    <Grid container columns={{ md: 2 }} alignItems="center">
                                        <Grid item md={2}>
                                            <Typography variant="body2" sx={{ fontSize: '15px', fontWeight: 'bold' }}>
                                                معلومات الباقة والرصيد
                                            </Typography>
                                        </Grid>
                                        <Grid item md={2}>
                                            <Typography variant="body2" sx={{ fontSize: '10px', color: "EFF0F7" }}>
                                                اداره البقاء والرصيد المتبقي للدفع
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>
                    <Grid item xs={1} sm={1} md={3} >
                        <Paper elevation={0} className={classes.card} onClick={() => toggleContent(4)}>
                            <Grid container spacing={1} columns={{ md: 3 }} alignItems="center">
                                <Grid item md={0.5} className={classes.icon}>
                                    <img src={linkingIcon} width={30} />
                                </Grid>
                                <Grid item md={2.5} >
                                    <Grid container columns={{ md: 2 }} alignItems="center">
                                        <Grid item md={2}>
                                            <Typography variant="body2" sx={{ fontSize: '15px', fontWeight: 'bold' }}>
                                                الربط والتضمين
                                            </Typography>
                                        </Grid>
                                        <Grid item md={2}>
                                            <Typography variant="body2" sx={{ fontSize: '10px', color: "EFF0F7" }}>
                                                اضافه نموذج التسجيل للنشره البريدية
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>
                    <Grid item xs={1} sm={1} md={3}>
                        {/* empty grid */}
                    </Grid>
                </Grid> </div>}

            {!isOpenContent && <div>
                <Grid container spacing={2} columns={{ xs: 1, sm: 1, md: 12, lg: 12 }} justifyContent={'center'}>
                    <Grid item md={8} lg={8}>
                        <Paper elevation={0} style={{ padding: '30px 60px' }}>
                            {renderContent()}
                        </Paper>
                    </Grid>
                    <Grid item md={2.5} lg={2.5}>
                        <Grid container direction="column" spacing={2}>
                            <Grid item >
                                <Paper
                                    elevation={0}
                                    style={{
                                        padding: '20px',
                                        cursor: 'pointer',
                                        border: selectedGrid === 0 ? '1.5px solid #FF6150' : 'none',
                                    }}>
                                    <Grid container spacing={1} columns={{ md: 3 }} alignItems="center" onClick={() => handleGridClick(0)}>
                                        <Grid item md={0.5} className={classes.icon}>
                                            <img src={profileSettingsIcon} width={30} />
                                        </Grid>
                                        <Grid item md={2.5} >
                                            <Grid container alignItems="center">
                                                <Grid item md={"auto"}>
                                                    <Typography variant="body2" sx={{ fontSize: '15px', fontWeight: 'bold' }}>
                                                        اعدادات الملف الشخصي
                                                    </Typography>
                                                </Grid>
                                                <Grid item md={"auto"}>
                                                    <Typography variant="body2" sx={{ fontSize: '10px', color: "EFF0F7" }}>
                                                        معلومات شخصية ومعلومات الشركة
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Paper>
                            </Grid>
                            <Grid item >
                                <Paper
                                    elevation={0}
                                    style={{
                                        padding: '20px',
                                        cursor: 'pointer',
                                        border: selectedGrid === 1 ? '1.5px solid #FF6150' : 'none',
                                    }}>
                                    <Grid container spacing={1} columns={{ md: 3 }} alignItems="center" onClick={() => handleGridClick(1)}>
                                        <Grid item md={0.5} className={classes.icon}>
                                            <img src={bulletinSettingsIcon} width={30} />
                                        </Grid>
                                        <Grid item md={2.5} >
                                            <Grid container columns={{ md: 2 }} alignItems="center" >
                                                <Grid item md={2}>
                                                    <Typography variant="body2" sx={{ fontSize: '15px', fontWeight: 'bold' }}>
                                                        اعدادات النشرة
                                                    </Typography>
                                                </Grid>
                                                <Grid item md={2}>
                                                    <Typography variant="body2" sx={{ fontSize: '10px', color: "EFF0F7" }}>
                                                        اداره البريد المرسل والمظهر العام للنشره
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Paper>
                            </Grid>
                            <Grid item >
                                <Paper
                                    elevation={0}
                                    style={{
                                        padding: '20px',
                                        cursor: 'pointer',
                                        border: selectedGrid === 2 ? '1.5px solid #FF6150' : 'none',
                                    }}>
                                    <Grid container spacing={1} columns={{ md: 3 }} alignItems="center" onClick={() => handleGridClick(2)}>
                                        <Grid item md={0.5} className={classes.icon}>
                                            <img src={userManagementIcon} width={30} />
                                        </Grid>
                                        <Grid item md={2.5} >
                                            <Grid container columns={{ md: 2 }} alignItems="center" >
                                                <Grid item md={2}>
                                                    <Typography variant="body2" sx={{ fontSize: '15px', fontWeight: 'bold' }}>
                                                        إدارة المستخدمين
                                                    </Typography>
                                                </Grid>
                                                <Grid item md={2}>
                                                    <Typography variant="body2" sx={{ fontSize: '10px', color: "EFF0F7" }}>
                                                        اضافه وحذف المستخدمين وتغيير الصلاحيات
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Paper>
                            </Grid>
                            <Grid item >
                                <Paper
                                    elevation={0}
                                    style={{
                                        padding: '20px',
                                        cursor: 'pointer',
                                        border: selectedGrid === 3 ? '1.5px solid #FF6150' : 'none',
                                    }}>
                                    <Grid container spacing={1} columns={{ md: 3 }} alignItems="center" onClick={() => handleGridClick(3)}>
                                        <Grid item md={0.5} className={classes.icon}>
                                            <img src={packageIcon} width={30} />
                                        </Grid>
                                        <Grid item md={2.5} >
                                            <Grid container columns={{ md: 2 }} alignItems="center">
                                                <Grid item md={2}>
                                                    <Typography variant="body2" sx={{ fontSize: '15px', fontWeight: 'bold' }}>
                                                        معلومات الباقة والرصيد
                                                    </Typography>
                                                </Grid>
                                                <Grid item md={2}>
                                                    <Typography variant="body2" sx={{ fontSize: '10px', color: "EFF0F7" }}>
                                                        اداره البقاء والرصيد المتبقي للدفع
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Paper>
                            </Grid>
                            <Grid item >
                                <Paper
                                    elevation={0}
                                    style={{
                                        padding: '20px',
                                        cursor: 'pointer',
                                        border: selectedGrid === 4 ? '1.5px solid #FF6150' : 'none',
                                    }}>
                                    <Grid container spacing={1} columns={{ md: 3 }} alignItems="center" onClick={() => handleGridClick(4)}>
                                        <Grid item md={0.5} className={classes.icon}>
                                            <img src={linkingIcon} width={30} />
                                        </Grid>
                                        <Grid item md={2.5} >
                                            <Grid container columns={{ md: 2 }} alignItems="center">
                                                <Grid item md={2}>
                                                    <Typography variant="body2" sx={{ fontSize: '15px', fontWeight: 'bold' }}>
                                                        الربط والتضمين
                                                    </Typography>
                                                </Grid>
                                                <Grid item md={2}>
                                                    <Typography variant="body2" sx={{ fontSize: '10px', color: "EFF0F7" }}>
                                                        اضافه نموذج التسجيل للنشره البريدية
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Paper>
                            </Grid>

                        </Grid>
                    </Grid>

                </Grid>
            </div >}

            <Snackbar
                open={snackBar.isOpen}
                autoHideDuration={6000}
                onClose={() => setSnackBar({ ...snackBar, isOpen: false })}
                message={snackBar.message}
            />
        </>

    );
};


const mapStateToProps = (state) => {
    return state
}
const dispatchData = (dispatch) => {
    return {
        setData: (data) => { dispatch({ type: 'SET_DATA', data: data }) },
        updateUser: (userdata) => dispatch({ type: 'UPDATE_USER', value: userdata })
    }
}
export default connect(mapStateToProps, dispatchData)(Settings);
