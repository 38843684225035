import React, { useState } from 'react';
import {
    Button,
    TextField,
    Grid,
    Typography,
    Alert
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { getAuth, fetchSignInMethodsForEmail, sendPasswordResetEmail } from 'firebase/auth';
import { doc, addDoc, deleteDoc, getDoc, getFirestore, getDocs, documentId, setDoc, collection, query, where } from 'firebase/firestore';
import backgroundImage from '../Public/assets/img/mujaz/mujaz-bg.jpg';

const ForgetPassword = () => {
    const [email, setEmail] = useState('');
    const [emailError, setEmailError] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [alertObj, setAlertObj] = useState({
        severity: '',
        message: ''
    });
    const auth = getAuth();
    const db = getFirestore();


    const onSubmit = () => {
        if (email == '') {
            setAlertObj({
                severity: 'error',
                message: 'الرجاء إدخال عنوان البريد الإلكتروني'
            });
            //popup error please fill email address
            return;
        }
        const emailPattern = /\S+@\S+\.\S+/;
        const isEmail = emailPattern.test(email);
        if (!isEmail) {
            setEmailError(true);
            setAlertObj({
                severity: 'error',
                message: 'عنوان البريد الإلكتروني غير صالح'
            });
            //pop up error please fill a valid email
            return;
        }
        setIsLoading(true);
        fetchSignInMethodsForEmail(auth, email)
            .then((signInMethods) => {
                if (signInMethods.length > 0) {
                    sendPasswordResetEmail(auth, email)
                        .then((data) => {
                            setAlertObj({
                                severity: 'success',
                                message: 'تم إرسال البريد الإلكتروني الخاص بإعادة تعيين كلمة المرور بنجاح'
                            });
                            setIsLoading(false);
                        })
                } else {
                    //email doesn't exists
                    setAlertObj({
                        severity: 'error',
                        message: 'البريد الإلكتروني غير مسجل'
                    });
                    setIsLoading(false);
                }
            })
    };

    return (
        <>
            <section className="wrapper image-wrapper bg-overlay bg-overlay-light-300 text-white"
             data-image-src="../Public/assets/img/mujaz/mujaz-bg.jpg"
             style={{backgroundImage: `url(${backgroundImage})`}}
             >
                <div className="container pt-17 pb-20 pt-md-19 pb-md-21 text-center">
                    <div className="row">
                        <div className="col-lg-8 mx-auto">
                            <h1 className="display-1 mb-3">هل نسيت كلمة السر؟</h1>
                            <nav className="d-inline-block" aria-label="breadcrumb">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item white-text"><a href="/">الرئيسية</a></li>
                                    <li className="breadcrumb-item active white-text" aria-current="page">نسيت كلمة السر</li>
                                </ol>
                            </nav>

                        </div>

                    </div>

                </div>
            </section>

            <section className="wrapper bg-light">
                <div className="container pb-14 pb-md-16">
                    <div className="row">
                        <div className="col-lg-7 col-xl-6 col-xxl-5 mx-auto mt-n20">
                            <div className="card">
                                <div className="card-body p-11 text-center">
                                    {Boolean(alertObj.message) && (
                                        <Alert severity={alertObj.severity} style={{ width: '100%' }}>
                                            <Typography>{alertObj.message}</Typography>
                                        </Alert>
                                    )}
                                    <Grid container spacing={2} columns={{ xs: 1, sm: 1, md: 1 }}>
                                        <Grid item xs={1} sm={1} md={1}>
                                            <Typography variant='body2' className="lead mb-6 text-end">الرجاء إدخال عنوان البريد الإلكتروني الخاص بك</Typography>
                                        </Grid>
                                        <Grid item xs={1} sm={1} md={1}>
                                            <TextField
                                                fullWidth
                                                type="email"
                                                onChange={(event) => setEmail(event.target.value)}
                                                value={email}
                                                label="البريد الإلكتروني"
                                                variant="outlined"
                                                id="loginEmail"
                                            />
                                        </Grid>
                                        <Grid item xs={1} sm={1} md={1}>
                                            <LoadingButton
                                                className="btn btn-primary btn-login w-100 mb-2"
                                                variant="contained"
                                                onClick={() => onSubmit()}
                                                loading={isLoading}
                                                disabled={isLoading}
                                            >أرسل رابط إعادة تعيين كلمة المرور</LoadingButton>
                                            {/* <Button
                                                onClick={() => onSubmit()}
                                                variant="contained"
                                                className="btn btn-primary rounded-pill btn-login w-100 mb-2">أرسل رابط إعادة تعيين كلمة المرور</Button> */}
                                        </Grid>
                                    </Grid>
                                    <p className="mb-0">ليس لديك حساب؟ <a href="./signup" className="hover">تسجيل حساب جديد</a></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>

    );
};


export default ForgetPassword;
